import React, {
  useReducer,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from "react";
import useCustomer from "app/state/hooks/customer/useCustomer";
import { CUSTOMER_PRODUCT, PRODUCT } from "../catalog/product/queries";
import { useQuery } from "react-apollo-hooks";
import { CustomPriceProvider } from "./customPriceContext";
import GenericPDP from "./genericPDP";
import SkeletonPDP from "./skeletonPDP";
import produce from "immer";
import { generateSkuAndProductFromUrl } from "./utils";
import CustomPDP from "./customPDP";
import { useLocation } from "react-router-dom";
import SeoContainer from "../catalog/product/seoContainer";
import FabricFinishPDP from "./fabricFinishPDP";
import { getBadges } from "../collections/utils";

const initialOptionState = {
  contrasting_welt: false,
  contrasting_buttons: false,
  inside_back: false,
  outside_back: false,
  cushion: false,
  tight_seat: false,
  contrast_welt: false,
  back_pillow: false,
  main_fabric: false,
  throw_pillow_contrast: false,
  throw_pillow_contrast_welt: false,
  nail_finish: false,
  nail_size: false,
};

const actions = {
  setAttribute: (state, action) => {
    state[action.attribute] = action.payload;
  },
  clearAttribute: (state, action) => {
    state[action.attribute] = [
      "contrasting_welt",
      "contrasting_buttons",
      "inside_back",
      "outside_back",
      "cushion",
      "tight_seat",
      "contrast_welt",
      "back_pillow",
      "throw_pillow_contrast",
    ].some((attribute) => attribute === action.attribute)
      ? state.main_fabric
      : false;
    state.nail_finish =
      action.attribute === "nail_size" ? false : state.nail_finish;
    state.nail_size =
      action.attribute === "nail_finish" ? false : state.nail_size;
  },
  setMainFabric: (state, action) => {
    state.main_fabric = action.payload;
    state.contrasting_welt = action.payload;
    state.contrasting_buttons = action.payload;
    state.inside_back = action.payload;
    state.outside_back = action.payload;
    state.cushion = action.payload;
    state.tight_seat = action.payload;
    state.contrast_welt = action.payload;
    state.back_pillow = action.payload;
    state.throw_pillow_contrast = action.payload;
  },
};

const reducer = produce((state, action) =>
  actions.hasOwnProperty(action.type)
    ? actions[action.type](state, action)
    : state
);

const Product = (props) => {
  const location = useLocation();
  const customer = useCustomer().isLoggedIn();
  const [optionState, optionDispatch] = useReducer(reducer, initialOptionState);
  // const queryString = require("query-string");
  // const parsedParameters = queryString.parse(props.location?.search);

  const [sku, setSku] = useState(
    props.data?.urlResolver?.sku || props.data?.sku
  );

  let isEdit = useMemo(
    () => (window.location.search?.includes("edit") ? true : false),
    []
  );

  const [rerenderCount, setRerenderCount] = useState(0);
  const [finishError, setFinishError] = useState(false);

  useEffect(() => {
    if (optionState?.finish) {
      setFinishError(false);
    }
  }, [optionState]);

  const FINAL_QUERY = customer ? CUSTOMER_PRODUCT : PRODUCT;

  let initialProduct = null;

  const isCustom = props.isCustom || false;
  const type = isCustom ? props.type : false;

  if (isCustom) {
    initialProduct = generateSkuAndProductFromUrl(props.location.pathname, type)
      .initialProduct;
  }

  useEffect(() => {
    if (!isCustom) setSku(props.data?.sku || props.data?.urlResolver?.sku);
  }, [props.data?.sku, props.data?.urlResolver?.sku]);

  useEffect(() => {
    if (isCustom) {
      setSku(generateSkuAndProductFromUrl(props.location.pathname, type).sku);
    }
  }, [location.pathname]);
  const variables = useMemo(
    () =>
      isCustom
        ? { filter: { sku: { eq: sku } }, search: sku }
        : { filter: { sku: { eq: sku } } },
    [isCustom, sku]
  );

  const { data: productData, loading: productLoading } = useQuery(FINAL_QUERY, {
    variables,
    fetchPolicy: "no-cache",
  });

  const [product, setProduct] = useState({});
  useEffect(() => {
    if (productData?.products?.items?.[0]) {
      setProduct(productData.products.items[0]);
    }
  }, [productLoading, productData]);

  useEffect(() => {
    if (!productLoading && productData?.products)
      setRerenderCount(rerenderCount + 1);
  }, [productData, productLoading]);

  useEffect(() => {
    if (!optionState.main_fabric && productData?.products?.items[0]) {
      optionDispatch({
        type: "setMainFabric",
        payload: productData.products.items[0].default_fabric,
      });
    }
  }, [productData?.products?.items, optionState.main_fabric]);

  useEffect(() => {
    if (location.search) {
      if (location.search?.includes("edit")) {
        location.search = location.search.replace("?edit=true", "");
      }

      const params =
        !window.location.href.includes("monogram") && location.search
          ? JSON.parse(
              '{"' +
                decodeURI(location.search.substr(1))
                  .replace(/"/g, '\\"')
                  .replace(/&/g, '","')
                  .replace(/=/g, '":"') +
                '"}'
            )
          : {};
      Object.keys(params)
        .filter((key) => key !== "firstname" && key !== "email")
        .forEach((key) => {
          if (key !== "edit") {
            optionDispatch({
              type: "setAttribute",
              attribute: key,
              payload:
                /^[0-9]*$/.test(params[key]) && key !== "finish"
                  ? Number.parseInt(params[key])
                  : params[key],
            });
          }
        });
    }
  }, [location.search]);

  const casters = useMemo(() => {
    let casterValue = 0;
    let skuArray = product?.sku?.split("-");
    if (skuArray && skuArray.length) {
      let skuCaster = skuArray[skuArray.length - 1];
      switch (skuCaster) {
        case "A2":
          casterValue = 2;
          break;
        case "A4":
          casterValue = 4;
          break;
        case "35":
        case "1N":
          casterValue = 5;
          break;
        default:
          break;
      }
    }
    return casterValue;
  }, [product.sku]);

  const calculateOptions = useCallback(
    (optionState) => {
      if (productData?.products?.items[0]) {
        return Object.keys(optionState)
          .reduce(
            (accumulator, optionKey) =>
              !!optionState[optionKey] &&
              productData.products.items[0].options?.some(
                (option) =>
                  option?.title?.toLowerCase().split(" ").join("_") ===
                  optionKey
              )
                ? accumulator.concat([
                    {
                      id: productData.products.items[0].options.find(
                        (option) =>
                          option?.title?.toLowerCase().split(" ").join("_") ===
                          optionKey
                      ).option_id,
                      value_string: optionState[optionKey],
                    },
                  ])
                : accumulator,
            []
          )
          .concat(
            ["fringe", "decorative_cord"].reduce(
              (accumulator, current) =>
                !!optionState[current] &&
                productData.products.items[0].options?.some(
                  (option) =>
                    option?.title?.toLowerCase().split(" ").join("_") ===
                    current
                )
                  ? accumulator.concat([
                      {
                        id: productData.products.items[0].options.find(
                          (option) =>
                            option?.title
                              ?.toLowerCase()
                              .split(" ")
                              .join("_") === current
                        ).option_id,
                        value_string: optionState[current + "_upcharge"],
                      },
                    ])
                  : accumulator,
              []
            )
          )
          .concat(
            productData.products.items[0].options?.some(
              (option) =>
                option?.title?.toLowerCase().split(" ").join("_") ===
                "build_your_own_configurator_url"
            )
              ? [
                  {
                    id: productData.products.items[0].options.find(
                      (option) =>
                        option?.title?.toLowerCase().split(" ").join("_") ===
                        "build_your_own_configurator_url"
                    ).option_id,
                    value_string: !isCustom
                      ? location.pathname +
                        "?" +
                        Object.keys(optionState)
                          .filter((key) => !!optionState[key])
                          .map((key) => `${key}=${optionState[key]}`)
                          .join("&")
                      : location.pathname.substr(
                          0,
                          location.pathname.lastIndexOf("/") + 1
                        ) +
                        sku +
                        "?" +
                        Object.keys(optionState)
                          .filter(
                            (key) =>
                              !!optionState[key] &&
                              key !== "build_your_own_options"
                          )
                          .map((key) => `${key}=${optionState[key]}`)
                          .join("&"),
                  },
                ]
              : []
          );
      }
      return [];
    },
    [productData?.products?.items, sku]
  );

  const downloadTearSheet = useMemo(() => {
    const prefixes = {
      "Upholstery Options": "upholstery_",
      "Pillow Upholstery Options": "pillow_upholstery_",
      "Nailhead Trim Options": "nail_head_",
      "Additional Options": "additional_options_",
      "View More Options": "more_options_",
      Finish: "selected_finish_",
    };
    return Object.keys(optionState)
      .reduce((options, optionKey) => {
        return !!optionState[optionKey] &&
          productData?.products?.items[0]?.options?.some(
            (option) =>
              option?.title?.toLowerCase().split(" ").join("_") === optionKey
          )
          ? options.concat([
              {
                ...productData?.products?.items[0].options?.find(
                  (option) =>
                    option?.title?.toLowerCase().split(" ").join("_") ===
                    optionKey
                ),
                selectedValue: optionState[optionKey],
              },
            ])
          : options;
      }, [])
      .filter((option) => !!prefixes[option?.category_options])
      .map((option) =>
        option?.title === "Finish"
          ? `${prefixes["Finish"]}${option?.title}=${option?.selectedValue}`
          : `${prefixes[option?.category_options] || ""}${option?.title}=${
              /[A-z-]/.test(option?.selectedValue)
                ? option?.selectedValue
                : option?.value?.find(
                    (val) => val.option_type_id === option?.selectedValue
                  )?.title
            }`
      )
      .join("&");
  }, [productData?.products?.items, optionState]);

  useEffect(() => {
    return () => {
      setRerenderCount(0);
    };
  }, []);

  if (productLoading && rerenderCount < 1) {
    return <SkeletonPDP />;
  }

  let availableIn = getBadges(product);

  let mappedSpecs = [];

  product.description &&
    mappedSpecs.push({
      name: "Description",
      value: product.description,
    });
  function getFinishOptions() {
    // if (product.finish_req) {
    //   if (product.allowed_finishes === "Y") {
    //     return "Available";
    //   }
    //   if (product.allowed_finishes === "*M") {
    //     return "Monogram Finishes";
    //   } else if (product.allowed_finishes === "*A") {
    //     return "Standard Upholstery Finishes and FairShield+ Finishes";
    //   } else if (product.allowed_finishes === "*N" && !product.finish) {
    //     return "Not Available";
    //   } else return "";
    // } else return "Not Available";
    if (product.finish_req) {
      if (product.allowed_finishes === "Y") {
        return "Yes";
      }
      if (product.allowed_finishes === "*M") {
        return "Yes";
      } else if (product.allowed_finishes === "*A") {
        return "Yes";
      } else if (product.allowed_finishes === "*N" && !product.finish) {
        return "No";
      } else return "";
    } else return "No";
  }

  mappedSpecs.push(
    {
      name: "Details",
      options: [
        // { name: "Additional Info", value: product.additional_info },
        { name: "Materials", value: product.materials_label },
        { name: "Standard Finish", value: product.finish },
        // {
        //   name: "Finish options",
        //   value: getFinishOptions(),
        // },
        {
          name: "Custom Finishes",
          value: getFinishOptions(),
        },
        {
          name: "Assembly Required",
          value: product.assem_req === 1 ? "Yes" : product.assem_req,
        },
        {
          name: "Nailhead Small",
          value: product.nailhead_small,
        },
        {
          name: "Nailhead Medium",
          value: product.nailhead_medium,
        },

        { name: "Nailhead application", value: product.nailhead_application },
        // { name: "Fabric base yardage", value: product.fabric_base_yardage },
        { name: "COM", value: product.fabric_base_yardage, suffix: " yards" },
        // {
        //   name: "Available in leather",
        //   value:
        //     product.available_in_leather === 1
        //       ? "Yes"
        //       : product.available_in_leather,
        // },
        { name: "Standard pillows", value: product.standard_pillows },

        // {
        //   name: "Leather base square feet",
        //   value: product.leather_base_square_feet,
        // },
        {
          name: "COL",
          value: product.leather_base_square_feet,
          suffix: " sq ft",
        },
      ],
    },
    {
      name: "Specifications",
      options: [
        {
          name: "Moisture Barrier Decking",
          value: product.moisture_barrier_standard === 1 ? "Standard" : null,
        },
        { name: "Standard seat", value: product.standard_seat },
        { name: "Standard back", value: product.standard_back },
        { name: "Seat construction", value: product.seat_construction },
        { name: "Back construction", value: product.back_conctruction },
        { name: "Spring construction", value: product.spring_construction },
        { name: "Casters", value: casters },
        { name: "Cube", value: product.cube },
        { name: "Weight (lbs)", value: product.weight },
        { name: "Country of origin", value: product.country_of_origin },
      ],
    },
    {
      name: "Dimensions (in)",
      options: [
        { name: "Width", value: product.width, suffix: "”" },
        { name: "Depth", value: product.depth, suffix: "”" },
        {
          name: "Frame depth",
          value:
            product.frame_lowest_depth &&
            product.frame_lowest_depth !== "0" &&
            product.frame_lowest_depth !== ".00" &&
            product.frame_highest_depth &&
            product.frame_highest_depth !== "0" &&
            product.frame_highest_depth !== ".00"
              ? `${product.frame_lowest_depth}” - ${product.frame_highest_depth}”`
              : product.frame_lowest_depth,
          suffix: "”",
        },
        {
          name: "Height",
          value:
            product.office_lowest_overall_height &&
            product.office_lowest_overall_height !== "0" &&
            product.office_lowest_overall_height !== ".00" &&
            product.office_highest_overall_height &&
            product.office_highest_overall_height !== "0" &&
            product.office_highest_overall_height !== ".00"
              ? `${product.office_lowest_overall_height}” - ${product.office_highest_overall_height}”`
              : product.height,
          suffix: "”",
        },
        {
          name: "Arm height",
          value:
            product.office_lowest_arm_height &&
            product.office_lowest_arm_height !== "0" &&
            product.office_lowest_arm_height !== ".00" &&
            product.office_highest_arm_height &&
            product.office_highest_arm_height !== "0" &&
            product.office_highest_arm_height !== ".00"
              ? `${product.office_lowest_arm_height}” - ${product.office_highest_arm_height}”`
              : product.arm_height,
          suffix: "”",
        },
        {
          name: "Inside seat width",
          value: product.inside_seat_width,
          suffix: "”",
        },
        {
          name: "Inside seat depth",
          value: product.inside_seat_depth,
          suffix: "”",
        },
        {
          name: "Seat height",
          value:
            product.office_lowest_seat_height &&
            product.office_lowest_seat_height !== "0" &&
            product.office_lowest_seat_height !== ".00" &&
            product.office_highest_seat_height &&
            product.office_highest_seat_height !== "0" &&
            product.office_highest_seat_height !== ".00"
              ? `${product.office_lowest_seat_height}” - ${product.office_highest_seat_height}”`
              : product.seat_height,
          suffix: "”",
        },
        {
          name: "Inside back height",
          value: product.inside_back_height,
          suffix: "”",
        },
        { name: "Reclined depth", value: product.reclined_depth, suffix: "”" },
        { name: "TV Recline", value: product.tv_recline, suffix: "”" },
        { name: "Wall Clearance", value: product.wall_clearance, suffix: "”" },
        {
          name: "Full lift wall clearance",
          value: product.full_lift_wall_clearance,
          suffix: "”",
        },
        { name: "Apron height", value: product.apron_height, suffix: "”" },
      ],
    }
  );

  return (
    <>
      <SeoContainer
        productData={product}
        type={"product"}
        jsonLdType={"Product"}
      />
      <CustomPriceProvider>
        {isCustom ? (
          <CustomPDP
            product={product}
            loading={productLoading}
            calculateOptions={() => calculateOptions(optionState)}
            downloadTearSheet={downloadTearSheet}
            optionDispatch={optionDispatch}
            optionState={optionState}
            availableIn={availableIn}
            mappedSpecs={mappedSpecs}
            type={type}
            sku={sku}
            setSku={setSku}
            initialProduct={initialProduct}
            isEdit={isEdit}
            finishError={finishError}
            setFinishError={setFinishError}
          />
        ) : product.attribute_set_name === "Finish" ||
          product.attribute_set_name === "Fabrics" ||
          product.attribute_set_name === "CustOptProducts" ? (
          <FabricFinishPDP
            product={product}
            fabric={product.attribute_set_name === "Fabrics"}
            customOpt={product.attribute_set_name === "CustOptProducts"}
            aggregations={productData?.products?.aggregations}
            calculateOptions={() => calculateOptions(optionState)}
          />
        ) : (
          <GenericPDP
            product={product}
            loading={productLoading}
            calculateOptions={() => calculateOptions(optionState)}
            downloadTearSheet={downloadTearSheet}
            optionDispatch={optionDispatch}
            optionState={optionState}
            availableIn={availableIn}
            mappedSpecs={mappedSpecs}
            relatedProducts={product.related_products}
            isEdit={isEdit}
            finishError={finishError}
            setFinishError={setFinishError}
          />
        )}
      </CustomPriceProvider>
    </>
  );
};

export default Product;
