import React, { useContext, useState } from "react";
import Icon from "app/assets/icon/icon";
import Button from "core/components/button";
import ConfigureProductModal from "./configureProductModal";
import CustomPriceContext from "../../customPriceContext";

const ConfigureProduct = ({
  product,
  price,
  options,
  optionDispatch,
  productImage,
  sku,
  leatherAvailable,
  isPelleProvisions,
  ffi_fullyuph,
  finishOption,
  customName,
  finishError,
  optionState,
  setFinishError,
  dimensionsString,
  customizationOptionsQueryParameters,
}) => {
  const { dispatch: priceDispatch } = useContext(CustomPriceContext);
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="configureProduct">
      <Button
        className={"configure-button generic-button-font"}
        onClick={() => setOpen(true)}
      >
        <Icon icon="eraser" />
        Configure Product
      </Button>
      {finishError && (
        <div className="w-full d-flex justify-content-end">
          <p className="error-finish-pdp">Finish is required</p>
        </div>
      )}
      <ConfigureProductModal
        price={price}
        product={product}
        options={options}
        optionState={optionState}
        optionDispatch={optionDispatch}
        productImage={productImage}
        sku={sku}
        leatherAvailable={leatherAvailable}
        isPelleProvisions={isPelleProvisions}
        ffi_fullyuph={ffi_fullyuph}
        finishOption={finishOption}
        customName={customName}
        finishError={finishError}
        setFinishError={setFinishError}
        priceDispatch={priceDispatch}
        isOpen={isOpen}
        setOpen={setOpen}
        dimensionsString={dimensionsString}
        customizationOptionsQueryParameters={
          customizationOptionsQueryParameters
        }
      />
    </div>
  );
};

export default ConfigureProduct;
