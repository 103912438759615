import React from "react";
import { MDBCol, MDBRow, MDBTypography, MDBBox, MDBIcon } from "mdbreact";
import { useSelector } from "react-redux";
import Price from "core/components/price";
import { Collapse } from "app/layout/Collapse";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import Image from "app/layout/image";
import CmsBlock from "app/layout/cmsBlock";

const InfoContainer = ({
  productData,
  aggregations,
  colorwaysRef,
  colorwaysItems,
}) => {
  let description = productData.description.html;
  let price =
    productData.price_range?.minimum_price?.final_price?.value || false;
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const selectedCategory = aggregations?.filter(
    (aggregation) => aggregation.attribute_code === "ffifabrics_category1"
  );
  const selectedCollection = aggregations?.filter(
    (aggregation) => aggregation.attribute_code === "ffifabrics_collection"
  );
  console.log(productData)

  return (
    <MDBRow className="fabric-info mb-4">
      <MDBCol size="12">
        <MDBRow>
          {productData.ffifabrics_vendor_image.image !== "" && (
            <MDBCol className={`mb-5 d-flex justify-content-end`}>
              <Image
                source={productData.ffifabrics_vendor_image.image}
                className="vendor-logo"
                alt={productData.ffifabrics_vendor_image.label}
                loaderAsSkeleton={true}
              />
            </MDBCol>
          )}
        </MDBRow>
      </MDBCol>
      <MDBCol size="12">
        {productData.pattern && (
          <MDBRow className="mb-3">
            <MDBCol size="6">
              <MDBTypography tag="h5" className={`body-2`}>
                Pattern
              </MDBTypography>
            </MDBCol>
            <MDBCol size="6">
              <MDBTypography tag="h5" className={`body-2 text-default`}>
                {productData.pattern}
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        )}
        {productData.color && (
          <MDBRow className="mb-3">
            <MDBCol size="6">
              <MDBTypography tag="h5" className={`body-2`}>
                Color
              </MDBTypography>
            </MDBCol>
            <MDBCol size="6">
              <MDBTypography tag="h5" className={`body-2 text-default`}>
                {productData.color}
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        )}
        {productData.grade && (
          <MDBRow className="mb-5">
            <MDBCol size="6">
              <MDBTypography tag="h5" className={`body-2`}>
                Grade
              </MDBTypography>
            </MDBCol>
            <MDBCol size="6">
              <MDBTypography tag="h5" className={`body-2 text-default`}>
                {productData.grade}
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        )}
        {isLoggedIn ? (
          <MDBRow className="mt-3">
            <MDBCol className="attribute-name">
              <MDBTypography tag="h4" className="fwmedium">
                Stock
              </MDBTypography>
            </MDBCol>
            <MDBCol className="attribute-value">
              <MDBTypography tag="h4" className="text-primary fwlight">
                {(productData.stockQty < 15 && "Out of Stock") ||
                  productData.stockQty +
                    `${
                      productData.ffifabrics_fabric || productData?.vinyl
                        ? " yards"
                        : productData?.leather
                        ? " sq. ft."
                        : ""
                    }`}
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        ) : productData.stockQty && productData.stockQty > 15 ? (
          <MDBRow className="mt-3">
            <MDBCol className="attribute-name">
              <MDBTypography tag="h4" className="fwmedium">
                Stock
              </MDBTypography>
            </MDBCol>
            <MDBCol className="attribute-value">
              <MDBTypography tag="h4" className="text-primary fwlight">
                In Stock
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        ) : (
          <MDBRow className="mt-3">
            <MDBCol className="attribute-name">
              <MDBTypography tag="h4" className="fwmedium">
                Stock
              </MDBTypography>
            </MDBCol>
            <MDBCol className="attribute-value">
              <MDBTypography tag="h4" className="text-primary fwlight">
                Out of Stock
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        )}
        {/* {price && (
          <MDBRow className="mt-3 mb-5 mb-lg-0">
            <MDBCol className="attribute-name">
              <MDBTypography tag="h4" className="fwmedium">
                Pricing per yard
              </MDBTypography>
            </MDBCol>
            <MDBCol className="attribute-value">
              <MDBTypography tag="h4" className="text-primary fwlight">
                <Price price={price} type={"fixed"} />
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        )} */}
      </MDBCol>
      <MDBCol size="12 mt-lg-5">
        {(typeof productData.abrasion !== "undefined" && (
          <Collapse
            className="my-2 custom-collapse"
            title="Specifications"
            isOpen={true}
          >
            {productData.abrasion && (
              <MDBRow>
                <MDBCol className={`mb-2`}>
                  <MDBTypography tag="h2" className="body-2">
                    Abrasion:
                  </MDBTypography>
                </MDBCol>
                <MDBCol className={`text-primary fs-16`}>
                  {productData.abrasion}
                </MDBCol>
              </MDBRow>
            )}
            {productData.country && (
              <MDBRow>
                <MDBCol className={`mb-2`}>
                  <MDBTypography tag="h2" className="body-2">
                    Country of Origin:
                  </MDBTypography>
                </MDBCol>
                <MDBCol className={`text-primary fs-16`}>
                  {productData.country}
                </MDBCol>
              </MDBRow>
            )}
            {productData.category && (
              <MDBRow>
                <MDBCol className={`mb-2`}>
                  <MDBTypography tag="h2" className="body-2">
                    Category:
                  </MDBTypography>
                </MDBCol>
                <MDBCol className={`text-primary fs-16`}>
                  {selectedCategory?.map((category) => {
                    return category?.options.map((option, index) => {
                      if (category?.options?.length > 1) {
                        if (category?.options?.length - 1 !== index) {
                          return option?.label.concat(", ");
                        }
                      }
                      return option?.label;
                    });
                  })}
                </MDBCol>
              </MDBRow>
            )}
            {productData.collection && selectedCollection?.length > 0 ? (
              <MDBRow>
                <MDBCol>
                  <MDBTypography tag="h2" className="body-2">
                    Collections:
                  </MDBTypography>
                </MDBCol>
                <MDBCol className={`text-primary fs-16`}>
                  {selectedCollection?.map((collection) => {
                    return collection?.options.map((option, index) => {
                      if (collection?.options.length > 1) {
                        if (collection?.options.length - 1 !== index) {
                          return option?.label.concat(", ");
                        }
                      }
                      return option?.label;
                    });
                  })}
                </MDBCol>
              </MDBRow>
            ) : (
              <></>
            )}
            {!!productData?.leather && productData.ffifabrics_finishtype?.[0] && productData.ffifabrics_finishtype?.[0] !== "false" && (
              <MDBRow>
                <MDBCol className={`mb-2`}>
                  <MDBTypography tag="h2" className="body-2">
                    Finish:
                  </MDBTypography>
                </MDBCol>
                <MDBCol className={`text-primary fs-16`}>
                  {productData.ffifabrics_finishtype?.[0]}
                </MDBCol>
              </MDBRow>
            )}
            {!productData.leather &&productData.ffifabrics_cleancode && productData.ffifabrics_cleancode !== "false" && (
              <MDBRow>
                <MDBCol className={`mb-2`}>
                  <MDBTypography tag="h2" className="body-2">
                    Cleaning Code:
                  </MDBTypography>
                </MDBCol>
                <MDBCol className={`text-primary fs-16`}>
                  {productData.ffifabrics_cleancode}
                </MDBCol>
              </MDBRow>
            )}
          </Collapse>
        )) || <></>}
        {(((productData.ffifabrics_std_width &&
          productData.ffifabrics_std_width !== ".00" &&
          productData.ffifabrics_std_width !== "0" &&
          productData.ffifabrics_std_width !== ".000") ||
          (productData.ffifabrics_vrepeat &&
            productData.ffifabrics_vrepeat !== ".00" &&
            productData.ffifabrics_vrepeat !== "0" &&
            productData.ffifabrics_vrepeat !== ".000") ||
          (productData.ffifabrics_hrepeat &&
            productData.ffifabrics_hrepeat !== ".00" &&
            productData.ffifabrics_hrepeat !== "0" &&
            productData.ffifabrics_hrepeat !== ".000") ||
          productData.ffifabrics_fullcont !== null) && (
          <Collapse className="my-2 custom-collapse" title="Details">
            {productData.ffifabrics_std_width &&
              productData.ffifabrics_std_width !== ".00" &&
              productData.ffifabrics_std_width !== "0" &&
              productData.ffifabrics_std_width !== ".000" && (
                <MDBRow>
                  <MDBCol>
                    <MDBTypography tag="h2" className="body-2">
                      Width:
                    </MDBTypography>
                  </MDBCol>
                  <MDBCol className={`text-primary fs-16`}>
                    {productData.ffifabrics_std_width}
                  </MDBCol>
                </MDBRow>
              )}
            {productData.ffifabrics_vrepeat &&
              productData.ffifabrics_vrepeat !== ".00" &&
              productData.ffifabrics_vrepeat !== "0" &&
              productData.ffifabrics_vrepeat !== ".000" && (
                <MDBRow>
                  <MDBCol>
                    <MDBTypography tag="h2" className="body-2">
                      Vertical Repeat:
                    </MDBTypography>
                  </MDBCol>
                  <MDBCol className={`text-primary fs-16`}>
                    {productData.ffifabrics_vrepeat}
                  </MDBCol>
                </MDBRow>
              )}
            {productData.ffifabrics_hrepeat &&
              productData.ffifabrics_hrepeat !== ".00" &&
              productData.ffifabrics_hrepeat !== "0" &&
              productData.ffifabrics_hrepeat !== ".000" && (
                <MDBRow>
                  <MDBCol>
                    <MDBTypography tag="h2" className="body-2">
                      Horizontal Repeat:
                    </MDBTypography>
                  </MDBCol>
                  <MDBCol className={`text-primary fs-16`}>
                    {productData.ffifabrics_hrepeat}
                  </MDBCol>
                </MDBRow>
              )}
            {(productData.ffifabrics_fullcont && (
              <MDBRow>
                <MDBCol size="12" className={`mb-3`}>
                  <MDBRow className={`align-items-center`}>
                    <MDBCol>
                      <MDBTypography tag="h2" className="body-2 mr-3">
                        Fabric Content:
                      </MDBTypography>
                    </MDBCol>
                    <MDBCol>
                      <MDBTypography className={`text-primary fs-16 m-0`}>
                        {productData.ffifabrics_fullcont}
                      </MDBTypography>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            )) || <></>}
          </Collapse>
        )) || <></>}
        {description && <Collapse className="my-2 custom-collapse" title="Cleaning and Care">
          <MDBTypography tag="h2" className="body-1">
            {description ? description : <></>}
          </MDBTypography>
        </Collapse>}
        {productData.standard_description && <Collapse className="my-2 custom-collapse" title="Description">
          <MDBTypography tag="h2" className="body-1">
            {productData.standard_description ? (
              productData.standard_description
            ) : (
              <></>
            )}
          </MDBTypography>
        </Collapse>}
      </MDBCol>
      {(colorwaysItems?.length !== 0 && (
        <MDBCol
          size="12"
          className={`colorways-clickable-section d-flex flex-lg-row flex-column`}
        >
          <MDBRow>
            <MDBCol
              size="12"
              md="7"
              className={`colorways-img-block pr-md-2 mb-3 mb-md-0`}
            >
              <CmsBlock id="fabrics_colorways_image" />
            </MDBCol>
            <MDBCol
              size="12"
              md="5"
              className={`body-6 text-white pl-md-2
          fwmedium text-center
           colorways-text-block cursor-pointer`}
              onClick={() => {
                window.scrollTo({
                  behavior: "smooth",
                  top: colorwaysRef.current.offsetTop,
                });
              }}
            >
              <MDBBox
                className={`p-2 bg-secondary h-100 d-flex flex-column align-items-center justify-content-center`}
              >
                <MDBBox tag="span" className={`mb-2`}>
                  Like this Fabric but looking for it in a different color?
                </MDBBox>
                <MDBBox className={`body-6 text-white fwregular underline`}>
                  View the Colorways Below
                </MDBBox>
                <MDBIcon icon="sort-down" />
              </MDBBox>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      )) || <></>}
    </MDBRow>
  );
};
export default InfoContainer;
