import React from "react";
import {
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBMask,
  MDBRow,
  MDBTypography,
  MDBView,
} from "mdbreact";
import Image from "app/layout/image";
import Icon from "app/assets/icon/icon";
import CardImage from "app/layout/cardImage";
import Hotspot from "app/layout/hotspot";
import themeConfiguration from "config/themeConfiguration";
import axios from "axios";

const GalleryDetail = ({
  description,
  // image,
  name,
  hotspots,
  toggleModal,
  bottomGalleryImages,
  mainImage,
}) => {
  const DownloadImages = async (urls) => {
    const url =
      themeConfiguration.magento_url +
      "rest/V1/jola-filedownloader/downloadFiles";
    const filesArray = [];
    urls.map((item, index) => {
      return (filesArray[index] = {
        url: item,
      });
    });
    const rawRequest = JSON.stringify({ type: "images", files: filesArray });
    const config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: rawRequest,
    };

    axios(config)
      .then((data) => {
        if (data.status === 200 && urls.length > 1) {
          window.open(data.data, "_blank");
        } else {
          CreateImageLink(data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const CreateImageLink = (data) => {
    const json_data = JSON.parse(data);
    const a = document.createElement("a");
    a.href = json_data.base64;
    a.download = json_data.image_name;
    a.click();
  };

  let numOfLinesInImagDes;
  let imageText;
  let checkForBrTag;
  let checkForDot;

  imageText =
    (description !== null &&
      ((typeof description.props !== "undefined" &&
        typeof description.props.dangerouslySetInnerHTML !== "undefined" &&
        description.props.dangerouslySetInnerHTML.__html) ||
        description)) ||
    "";
  checkForBrTag = (imageText !== "" && imageText.match(/<br>/g)) || "";
  checkForDot =
    (imageText !== "" &&
      imageText.match(/\./g) !== null &&
      imageText.match(/\./g).length) ||
    "";
  numOfLinesInImagDes = (checkForBrTag !== "" && checkForBrTag.length) || 0;

  return (
    <>
      <MDBRow className="">
        <MDBCol className="d-flex align-items-center">
          <MDBBox className={`d-flex w-100 pb-4 pt-4 pr-4 justify-content-end`}>
            <div
              role="button"
              onKeyUp={() => toggleModal(false)}
              tabIndex={0}
              className="button-2 back-link"
              onClick={() => toggleModal(false)}
              style={{ cursor: "pointer" }}
            >
              <Icon icon="closeDefault" />
            </div>
          </MDBBox>
        </MDBCol>
      </MDBRow>
      <MDBContainer className={"gallery-detail-container"}>
        {bottomGalleryImages.length ? (
          <MDBRow className="pt-3 align-items-center text-lg-left text-center">
            <MDBCol size="12" lg="6">
              {/* <MDBTypography tag="h1" dangerouslySetInnerHTML={{ __html: name }} /> */}
            </MDBCol>
            {bottomGalleryImages.length > 0 && (
              <MDBCol size="12" lg="6" className="text-lg-right">
                <MDBBtn
                  color="secondary"
                  onClick={() => {
                    let imagesArray = [mainImage];
                    bottomGalleryImages.forEach((relatedImg) => {
                      imagesArray.push(relatedImg.image);
                    });
                    DownloadImages(imagesArray);
                  }}
                >
                  Download all images
                </MDBBtn>
              </MDBCol>
            )}
          </MDBRow>
        ) : null}

        {/* {hotspots &&
      hotspots.length > 0 ? (
        <MDBRow className="py-4 gallery-detail-img">
          <MDBCol>
            {hotspots.map((hotspot, index) => (
              <div key={`name-${index}`}>
                <Hotspot
                  contentArray={hotspot}
                  isHome={true}
                  toggleModal={toggleModal}
                  DownloadImages={DownloadImages}
                  showMask={true}
                />
              </div>
            ))}
          </MDBCol>
        </MDBRow>
      ) : (
        <MDBRow className="py-4 gallery-detail-img">
          <MDBCol>
            <MDBView hover className="d-flex justify-content-center">
              <Image
                className="img-fluid w-100"
                source={mainImage}
                alt={name}
                loaderAsSkeleton={true}
              />
              <MDBMask overlay="black-light">
                <MDBBtn
                  className="download-image-small-button float-right"
                  onClick={() => DownloadImages([mainImage])}
                >
                  <Icon icon="downloadWhite" />
                </MDBBtn>
              </MDBMask>
            </MDBView>
          </MDBCol>
        </MDBRow>
      )} */}

        {description !== null ? (
          <MDBRow className="py-0">
            {/* <MDBCol size="2" lg="2">
            <MDBTypography tag="h2" className="body-1">
              As Shown:
            </MDBTypography>
          </MDBCol> */}
            <MDBCol size="12">
              <MDBRow>
                <MDBCol>
                  <CardImage
                    className="card-image d-flex flex-column p-0"
                    imageDescription={
                      <p dangerouslySetInnerHTML={{ __html: description }} />
                    }
                    imageLinkText={"Read More"}
                    imageLinkLessText={"Read Less"}
                    cardImgOnGallery={true}
                    numOfLinesInImagDes={numOfLinesInImagDes}
                    checkForDot={checkForDot}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        ) : (
          <></>
        )}
        {/* <MDBRow>
        {bottomGalleryImages.length % 3 === 0
          ? bottomGalleryImages.map((item, index) => {
              //every time it is 3th image show full width
              if ((index + 1) % 3 === 0) {
                return (
                  <MDBCol
                    size="12"
                    lg="12"
                    className={"my-2 bottom-gallery-images"}
                  >
                    <MDBView hover className={`d-flex justify-content-center`}>
                      <Image
                        className="img-fluid w-100"
                        source={item.image}
                        alt={item.name}
                        loaderAsSkeleton={true}
                      />
                      <MDBMask overlay="black-light">
                        <MDBBtn
                          className={"download-image-small-button float-right"}
                          onClick={() => DownloadImages([item.image])}
                        >
                          <Icon icon="downloadWhite" />
                        </MDBBtn>
                      </MDBMask>
                    </MDBView>
                  </MDBCol>
                );
              } else {
                return (
                  <MDBCol
                    size="12"
                    lg="6"
                    className={"my-2 bottom-gallery-images"}
                  >
                    <MDBView hover className={`d-flex justify-content-center`}>
                      <Image
                        className="img-fluid w-100"
                        source={item.image}
                        alt={item.name}
                        loaderAsSkeleton={true}
                      />
                      <MDBMask overlay="black-light">
                        <MDBBtn
                          className={"download-image-small-button float-right"}
                          onClick={() => DownloadImages([item.image])}
                        >
                          <Icon icon="downloadWhite" />
                        </MDBBtn>
                      </MDBMask>
                    </MDBView>
                  </MDBCol>
                );
              }
            })
          : bottomGalleryImages.length % 2 === 0
          ? bottomGalleryImages.map((item, index) => {
              return (
                <MDBCol
                  key={`key-${index}`}
                  size="12"
                  lg="6"
                  className={"my-2 bottom-gallery-images"}
                >
                  <MDBView hover className={`d-flex justify-content-center`}>
                    <Image
                      className="img-fluid w-100"
                      source={item.image}
                      alt={item.name}
                      loaderAsSkeleton={true}
                    />
                    <MDBMask overlay="black-light">
                      <MDBBtn
                        className={"download-image-small-button float-right"}
                        onClick={() => DownloadImages([item.image])}
                      >
                        <Icon icon="downloadWhite" />
                      </MDBBtn>
                    </MDBMask>
                  </MDBView>
                </MDBCol>
              );
            })
          : bottomGalleryImages.map((item, index) => {
              //every time it is 3th image show full width
              if (bottomGalleryImages.length === index + 1) {
                return (
                  <MDBCol
                    size="12"
                    lg="12"
                    className={"my-2 bottom-gallery-images"}
                  >
                    <MDBView hover className={`d-flex justify-content-center`}>
                      <Image
                        className="img-fluid w-100"
                        source={item.image}
                        alt={item.name}
                        loaderAsSkeleton={true}
                      />
                      <MDBMask overlay="black-light">
                        <MDBBtn
                          className={"download-image-small-button float-right"}
                          onClick={() => DownloadImages([item.image])}
                        >
                          <Icon icon="downloadWhite" />
                        </MDBBtn>
                      </MDBMask>
                    </MDBView>
                  </MDBCol>
                );
              } else {
                return (
                  <MDBCol
                    size="12"
                    lg="6"
                    className={"my-2 bottom-gallery-images"}
                  >
                    <MDBView hover className={`d-flex justify-content-center`}>
                      <Image
                        className="img-fluid w-100"
                        source={item.image}
                        alt={item.name}
                        loaderAsSkeleton={true}
                      />
                      <MDBMask overlay="black-light">
                        <MDBBtn
                          className={"download-image-small-button float-right"}
                          onClick={() => DownloadImages([item.image])}
                        >
                          <Icon icon="downloadWhite" />
                        </MDBBtn>
                      </MDBMask>
                    </MDBView>
                  </MDBCol>
                );
              }
            })}
      </MDBRow> */}
      </MDBContainer>
    </>
  );
};

export default GalleryDetail;
