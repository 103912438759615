// @ts-check

import { useQuery } from "@apollo/react-hooks";
import { offlineErrorMessage } from "app/config/config";
import CollectionsContext from "app/layout/collections/CollectionsContext";
import OfflinePage from "app/layout/OfflinePage";
import { useFavorites, useToggleFavorite } from "app/pages/collections/hooks";
import useCustomer from "core/state/hooks/customerHooks/useCustomer";
import gql from "graphql-tag";
import React, { useMemo } from "react";
import { Redirect } from "react-router-dom";
import Category from "./category";
import {
  CUSTOMER_PRODUCTS as SEARCH_CUSTOMER_PRODUCTS,
  PRODUCTS as SEARCH_PRODUCTS,
} from "app/pages/searchResultsPage/queries.js";
import { generateLaneFilerString } from "app/state/hooks/searchHooks/functions";
export const PRODUCT_AGGREGATIONS = gql`
  query productAggregations(
    $pageSize: Int
    $currentPage: Int
    $filter: ProductAttributeFilterInput
    $sort: ProductAttributeSortInput
    $search: String
  ) {
    products(
      pageSize: $pageSize
      currentPage: $currentPage
      filter: $filter
      sort: $sort
      search: $search
    ) {
      aggregations {
        attribute_code
        label
        count
        options {
          count
          label
          value
        }
      }
    }
  }
`;
export const PRODUCTS_QUERY = gql`
  query getCategoryProducts(
    $pageSize: Int
    $currentPage: Int
    $filter: ProductAttributeFilterInput
    $sort: ProductAttributeSortInput
    $search: String
    $force_default: Boolean
  ) {
    products(
      pageSize: $pageSize
      currentPage: $currentPage
      filter: $filter
      sort: $sort
      search: $search
      force_default: $force_default
    ) {
      total_count
      aggregations {
        attribute_code
        label
        count
        options {
          count
          label
          value
        }
      }
      items {
        product_features
        product_features_label
        attribute_set_name
        ffi_name
        ffi_extdesc2
        ffifabrics_pattern
        short_description {
          html
        }
        canonical_url
        ffi_cfgmodel
        color
        description {
          html
        }
        ... on CustomizableProductInterface {
          options {
            title
            category_options
            option_id
            ... on CustomizableDropDownOption {
              value {
                title
                price
                option_type_id
              }
            }
            ... on CustomizableCheckboxOption {
              value {
                title
                price
                option_type_id
              }
            }
            ... on CustomizableFieldOption {
              field_value: value {
                price
              }
            }
          }
        }

        default_fabric: ffi_dftfabric
        ffifabrics_category1
        ffi_lanect
        ffi_laners
        fficustopt_type_filterable
        ffi_lanesl
        ffi_isvisible
        ffi_collection
        is_new
        in_stock_filter
        ffi_assem_req
        ffi_leatheravl
        ffifabrics_grade
        image {
          url(width: 700, height: 700)
          label
        }
        small_image {
          url(width: 265, height: 265)
          label
        }
        url_key
        thumbnail {
          url(width: 150, height: 150)
          label
        }
        stock_status
        sku
        name
      }
      page_info {
        current_page
        page_size
        total_pages
      }
    }
  }
`;

export const CUSTOMER_PRODUCTS_QUERY = gql`
  query getCategoryProducts(
    $pageSize: Int
    $currentPage: Int
    $filter: ProductAttributeFilterInput
    $sort: ProductAttributeSortInput
    $search: String
    $force_default: Boolean
  ) {
    customer {
      email
    }
    products(
      pageSize: $pageSize
      currentPage: $currentPage
      filter: $filter
      sort: $sort
      search: $search
      force_default: $force_default
    ) {
      total_count
      aggregations {
        attribute_code
        label
        count
        options {
          count
          label
          value
        }
      }
      items {
        product_features
        product_features_label
        canonical_url
        short_description {
          html
        }
        ffi_extdesc2
        ffifabrics_pattern
        ffi_cfgmodel
        color
        attribute_set_name
        ffi_name
        description {
          html
        }
        ... on CustomizableProductInterface {
          options {
            title
            category_options
            option_id
            ... on CustomizableDropDownOption {
              value {
                title
                price
                option_type_id
              }
            }
            ... on CustomizableCheckboxOption {
              value {
                title
                price
                option_type_id
              }
            }
            ... on CustomizableFieldOption {
              field_value: value {
                price
              }
            }
          }
        }

        default_fabric: ffi_dftfabric
        ffifabrics_category1
        ffi_lanect
        ffi_laners
        ffi_lanesl
        ffi_isvisible
        fficustopt_type_filterable
        ffi_collection
        is_new
        in_stock_filter
        ffi_assem_req
        ffi_leatheravl
        ffifabrics_grade
        price_range {
          minimum_price {
            final_price {
              value
              currency
            }
          }
        }
        image {
          url(width: 700, height: 700)
          label
        }
        small_image {
          url(width: 265, height: 265)
          label
        }
        url_key
        thumbnail {
          url(width: 150, height: 150)
          label
        }
        stock_status
        ... on CustomizableProductInterface {
          options {
            title
            category_options
            option_id
            ... on CustomizableDropDownOption {
              value {
                title
                price
                option_type_id
              }
            }
            ... on CustomizableCheckboxOption {
              value {
                title
                price
                option_type_id
              }
            }
            ... on CustomizableFieldOption {
              field_value: value {
                price
              }
            }
          }
        }

        default_fabric: ffi_dftfabric
        sku
        name
        price_range {
          minimum_price {
            final_price {
              value
              currency
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
    }
  }
`;

const CATEGORY_LIST_QUERY = gql`
  query categoryList($filter: CategoryFilterInput) {
    categoryList(filters: $filter) {
      id
      name
      children {
        id
        name
        children {
          id
          name
          children {
            id
            name
            children {
              name
              id
              children {
                name
                id
                children {
                  name
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 *
 * @type {React.FC<*>}
 */
const CategoryQueryContainer = (props) => {
  const [, isLoggedIn] = useCustomer();
  const loggedIn = isLoggedIn();
  let {
    queryProps,
    setCurrentPage,
    categoryIds = [],
    setFinishCategory = () => {},
    finishCategory,
    aggregationProps,
  } = props;
  let updatedProps = { ...props };

  const favorites = useFavorites();

  const { setSelectedProduct } = React.useContext(CollectionsContext);
  //@ts-ignore
  const onToggleFavorite = useToggleFavorite(favorites, ([product]) => {
    setSelectedProduct(product);
  });

  if (window.location.pathname.includes("/instock")) {
    if (
      queryProps?.filter.category_id?.eq === props.categoryId ||
      !queryProps?.filter.category_id?.eq
    ) {
      delete queryProps?.filter.category_id;
    }
    queryProps.filter["in_stock_filter"] = { eq: 1 };
    queryProps.filter["filterable_attribute_set_name"] = { eq: "7059" };
  }

  if (props.categoryId)
    queryProps.filter.category_id = { eq: props.categoryId };

  if (props.searchVal) {
    queryProps.search = props.searchVal;
  }

  // if (categoryIds?.length && !props.categoryId) {
  //   queryProps.filter.category_id = {
  //     in: [
  //       ...categoryIds
  //         // @ts-ignore
  //         ?.filter((cat) => cat?.value)
  //         // @ts-ignore
  //         ?.map((category) => category?.value),
  //     ],
  //   };

  //   for (const key in props?.filter) {
  //     if (props?.filter[key] == true) queryProps.filter[key] = { eq: "1" };
  //   }
  //   if (props.searchVal) queryProps.search = props.searchVal;
  // }

  const { data: categoryDataQuery } = useQuery(CATEGORY_LIST_QUERY, {
    fetchPolicy: "no-cache",
    variables: { filters: { ids: { eq: "2375" } } },
    skip: !(
      window.location.pathname.includes("/instock") || props?.isSearchPage
    ),
  });

  if (props.isSearchPage) {
    queryProps.search = props.isExact
      ? '"' + props.searchVal?.toLowerCase() + '"'
      : props.searchVal?.toLowerCase();
    if (queryProps?.filter) {
      let arrAttributes = [];

      if (
        (props.categoryName === "products" || !props.categoryName) &&
        props?.searchParams?.category !== "fabrics-and-leather"
      ) {
        queryProps.filter.ffi_isvisible = { eq: "1" };
        arrAttributes = ["7059"];
        let filterLanePart = generateLaneFilerString(props.optionsVal);
        let filterLanePartJSON = JSON.parse(filterLanePart)
          ? JSON.parse(filterLanePart)
          : null;
        queryProps.filter = { ...queryProps.filter, ...filterLanePartJSON };
        // queryProps.filter.JSON.parse(filterLanePart)
      } else arrAttributes = ["7060"];
      queryProps.filter.filterable_attribute_set_name = { in: arrAttributes };
    }
  }

  const FINAL_QUERY = props.isSearchPage
    ? loggedIn
      ? SEARCH_CUSTOMER_PRODUCTS
      : SEARCH_PRODUCTS
    : loggedIn
    ? CUSTOMER_PRODUCTS_QUERY
    : PRODUCTS_QUERY;

  const { data, loading, error, refetch } = useQuery(FINAL_QUERY, {
    fetchPolicy: "no-cache",
    variables: { ...queryProps },
  });

  const { data: aggregationData } = useQuery(PRODUCT_AGGREGATIONS, {
    fetchPolicy: "no-cache",
    variables: { ...aggregationProps },
  });

  React.useEffect(() => props.setloadingData(data), [data]);

  updatedProps.toolbarProps.totalCount = data?.products?.total_count || 0;
  updatedProps.toolbarProps.totalPages = Math.ceil(
    (data?.products?.total_count || 1) / (props?.queryProps?.pageSize || 1)
  );
  updatedProps.listingProps = data?.products?.items || [];

  let categoriesList = useMemo(() => {
    // @ts-ignore
    let arr = [];
    let categories =
      // @ts-ignore
      categoryDataQuery?.categoryList?.[0]?.children
        // @ts-ignore
        ?.find((level) => level?.name === "Products")
        // @ts-ignore
        ?.children?.find((level1) => level1?.name === "Shop By Categories")
        ?.children;
    if (categories) {
      //@ts-ignore
      categories.map((val) => {
        arr.push({
          value: val?.id,
          label: val?.name,
        });
        if (val?.children)
          // @ts-ignore
          val.children.map((val2) =>
            arr.push({ value: val2?.id, label: `- ${val2?.name}` })
          );
      });
    }
    //@ts-ignore
    return arr;
  }, [categoryDataQuery, aggregationData?.products?.aggregations]);

  let categoryListDropdown = useMemo(() => {
    // @ts-ignore
    let filteredCategoriesList = [];
    if (categoryDataQuery?.categoryList) {
      if (aggregationData?.products?.aggregations) {
        const foundCategory = aggregationData?.products?.aggregations
          // @ts-ignore
          .find((option) => option?.attribute_code === "category_uid");
        // @ts-ignore
        filteredCategoriesList = categoriesList.filter((el) => {
          // @ts-ignore
          return foundCategory?.options.some((f) => {
            return +f?.value === el?.value;
          });
        });
      }
      // @ts-ignore
      return filteredCategoriesList;
    }
    return null;
  }, [categoryDataQuery, aggregationData?.products.aggregations, categoriesList]);

  if (error) {
    if (error.message === offlineErrorMessage) {
      return <OfflinePage refetch={refetch} />;
    } else {
      return <Redirect to="/" />;
    }
  }

  return (
    <Category
      {...updatedProps}
      data={data}
      finishCategory={finishCategory}
      categoryIds={categoryIds}
      setFinishCategory={setFinishCategory}
      aggregations={aggregationData?.products.aggregations ?? []}
      isLoggedIn={loggedIn}
      favorites={favorites}
      loadingData={loading}
      onToggleFavorite={onToggleFavorite}
      categoryListDropdown={categoryListDropdown}
      setCurrentPage={setCurrentPage}
      // showCollectionAsCategory={props.showCollectionAsCategory}
      // setShowCollectionAsCategory={props.setShowCollectionAsCategory}
    />
  );
};

export default CategoryQueryContainer;
