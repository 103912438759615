import Accordion from "app/layout/Accordion";
import Button from "core/components/button";
import Price from "core/components/price";
import useCustomer from "app/state/hooks/customer/useCustomer";
import React, { useContext, useMemo, useState } from "react";
import {
  inStockLogicMethod,
  quoteRequestButton,
} from "app/pages/product/components/toolbar/helper.js";
import Downloads from "./downloads/downloads";
import Icon from "app/assets/icon/icon";
import { useAddItemToCart, useUpdateItemsOnCart } from "app/helpers/cart/hooks";
import {
  concatToString,
  getMonogramIdsString,
  removeFromString,
  beforeAddToFavorites,
} from "app/pages/product/utils";
import {
  useFavorites,
  useToggleFavoriteProductParam,
  useWishlists,
} from "app/pages/collections/hooks";
import { useHistory, useLocation } from "react-router-dom";
import { isEqual } from "lodash";
import CollectionsContext from "app/layout/collections/CollectionsContext";
import { checkIfItemIsChecked } from "app/pages/collections/components/WishlistsModal/wishlistHelper";
import AlsoAvailable from "./alsoAvailable";
import ConfigureProduct from "app/pages/product/components/configureProduct";
import Summary from "app/pages/product/components/toolbar/components/summary";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import { useSelector } from "react-redux";

const RightSide = ({
  mappedSpecs,
  product,
  price,
  downloadTearSheet,
  isCustom,
  optionState,
  top = false,
  base = false,
  calculateOptions,
  isEdit = false,
  setFinishError,
  numOfBases = 1,
  options,
  optionDispatch,
  productImage,
  sku,
  leatherAvailable,
  isPelleProvisions,
  ffi_fullyuph,
  finishOption,
  customName,
  finishError,
}) => {
  const [addItemToCart, addItemToCartResult] = useAddItemToCart();
  const [updateItemOnCart, result] = useUpdateItemsOnCart();

  const favorites = useFavorites();
  const wishlists = useWishlists();
  const customer = useCustomer().isLoggedIn();
  const inStockLogic = useMemo(() => inStockLogicMethod(product, customer), [
    customer,
    product,
  ]);

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const isOutOfStock = inStockLogic === "Out of Stock";
  const location = useLocation();
  const history = useHistory();
  const toggleFavorite = useToggleFavoriteProductParam();

  const { setSelectedProduct, setIsFavorite, setShowModal } = useContext(
    CollectionsContext
  );

  const addToWishlistMethod = (productWithOptions) => {
    setSelectedProduct(productWithOptions);
    product.defaultOptions = calculateOptions();

    let arr = wishlists.filter((wishlist) =>
      wishlist.items.some(
        (wishistitem) =>
          wishistitem?.product?.sku === product?.sku &&
          checkIfItemIsChecked(product, wishistitem)
      )
    );

    let itemExist =
      favorites?.items.find(
        (item) =>
          item.product.sku === product.sku &&
          checkIfItemIsChecked(product, item)
      ) && areOptionsSame;

    if (itemExist && arr.length === 1) {
      history.replace({
        pathname: location.pathname,
        search: "",
      });
      setSelectedProduct(null);
      // if (isCustom) getBYOData();
      // else toggleFavorite(product, calculateOptions());
      toggleFavorite(product, calculateOptions());
    } else if (arr.length < 2 || !areOptionsSame) {
      setSelectedProduct(productWithOptions);
      // if (isCustom) getBYOData();
      // else toggleFavorite(product, calculateOptions());
      toggleFavorite(product, calculateOptions());
    } else {
      if (areOptionsSame) setShowModal(true);

      // getBYOData(true);
    }
  };

  let areOptionsSame = useMemo(() => {
    let options = window.location.pathname.includes("monogram")
      ? getMonogramIdsString(calculateOptions())
      : calculateOptions()?.length > 0
      ? calculateOptions()
      : product.defaultOptions;

    if (
      isEqual(
        removeFromString(location.search.replace("&edit=true", "")),
        window.location.pathname.includes("monogram")
          ? `?${options}`
          : `?${removeFromString(
              options?.[options?.length - 1]?.value_string
                ?.toString()
                ?.split("?")[1]
            )}`
      )
    )
      return true;
    return false;
  }, [
    location.search,
    product.defaultOptions,
    calculateOptions,
    wishlists,
    getMonogramIdsString,
  ]);

  const isInFavorites = useMemo(
    () =>
      favorites?.items.find(
        (favorite) => favorite.product.sku === product.sku
      ) && areOptionsSame,
    [favorites, areOptionsSame, product]
  );
  const description = useMemo(
    () => mappedSpecs?.find((spec) => spec?.name === "Description"),
    [mappedSpecs]
  );
  const details = useMemo(
    () =>
      mappedSpecs
        ?.find((spec) => spec?.name === "Details")
        ?.options?.filter(
          (option) =>
            option.value &&
            option.value !== ".00" &&
            option.value !== ".0" &&
            option.value !== "N/A" &&
            option.value !== "0"
        ),
    [mappedSpecs]
  );
  const dimensions = useMemo(
    () =>
      mappedSpecs
        ?.find((spec) => spec?.name === "Dimensions (in)")
        ?.options?.filter(
          (option) =>
            option.value &&
            option.value !== ".00" &&
            option.value !== ".0" &&
            option.value !== "N/A" &&
            option.value !== "0"
        ),
    [mappedSpecs]
  );
  const specifications = useMemo(
    () =>
      mappedSpecs
        ?.find((spec) => spec?.name === "Specifications")
        ?.options?.filter(
          (option) =>
            option.value &&
            option.value !== ".00" &&
            option.value !== ".0" &&
            option.value !== "N/A" &&
            option.value !== "0"
        ),
    [mappedSpecs]
  );

  const mappedOptions = useMemo(
    () =>
      options?.reduce(
        (accumulator, option) =>
          option?.category_options
            ? {
                ...accumulator,
                [option?.category_options.toLowerCase().split(" ").join("_")]: (
                  accumulator[
                    option?.category_options?.toLowerCase().split(" ").join("_")
                  ] || []
                ).concat(option),
              }
            : accumulator,
        {}
      ),
    [options]
  );

  const hasOptions = useMemo(() => {
    if (!mappedOptions) return false;
    return Object.keys(mappedOptions).length > 0;
    // for (const key in mappedOptions) {
    //   if (Array.isArray(mappedOptions[key])) {
    //     for (const item of mappedOptions[key]) {
    //       if (Array.isArray(item.value) && item?.value?.length > 1) {
    //         return true;
    //       }
    //     }
    //   }
    // }

    // return false;
  }, [mappedOptions]);
  console.log(mappedOptions);
  const [buildSummary, setBuildSummary] = useState(false);

  const dimensionsString = useMemo(() => {
    const width = dimensions?.find((dim) => dim?.name === "Width");
    const height = dimensions?.find((dim) => dim?.name === "Height");
    const depth = dimensions?.find((dim) => dim?.name === "Depth");

    let dimensionString = "";

    if (width) dimensionString += `${parseFloat(width.value).toFixed(2)}"w `;
    if (depth) dimensionString += `x ${parseFloat(depth.value).toFixed(2)}"d`;
    if (height)
      dimensionString += `x ${parseFloat(height.value).toFixed(2)}"h `;

    return dimensionString.trim().replace(/\s*x\s*/g, " x ");
  }, [dimensions]);
  const isFinishReqDone = useMemo(
    () => !product?.finish_req || optionState?.finish,
    // This commented out logic is for monogram (customPdp) from toolbar component
    // ||
    // (window.location.pathname.includes("monogram") &&
    //   getMonogramIdsString(calculateOptions())?.includes("finish"))
    [product.finish_req, optionState]
  );

  const separatedDetails = [
    ...dimensions,
    ...specifications,
    ...details,
  ].reduce(
    (acc, _, i) =>
      i % 12 === 0
        ? [
            ...acc,
            [...dimensions, ...details, ...specifications].slice(i, i + 12),
          ]
        : acc,
    []
  );

  return (
    <div className="rightSideGenericPDP">
      <h1 className={"product-name"}>{product?.name}</h1>
      {product?.ffi_extdesc2 && (
        <h4 className="generic-title-desc">{product?.ffi_extdesc2}</h4>
      )}
      <h4 className="generic-description">
        Style:{" "}
        <span className="generic-description generic-description-value">
          {product?.sku}
        </span>
      </h4>
      {dimensionsString && (
        <h4 className="generic-description">
          Dimensions:{" "}
          <span className="generic-description generic-description-value">
            {dimensionsString}
          </span>
        </h4>
      )}
      {product?.fabric_base_yardage &&
        product?.fabric_base_yardage !== ".0" &&
        product?.fabric_base_yardage !== ".00" &&
        product?.fabric_base_yardage !== "0" && (
          <h4 className="generic-description">
            COM:{" "}
            <span className="generic-description generic-description-value">
              {product?.fabric_base_yardage + " yards"}
            </span>
          </h4>
        )}
      {product?.leather_base_square_feet &&
        product?.leather_base_square_feet !== ".0" &&
        product?.leather_base_square_feet !== ".00" &&
        product?.leather_base_square_feet !== "0" && (
          <h4 className="generic-description">
            COL:{" "}
            <span className="generic-description generic-description-value">
              {product?.leather_base_square_feet + " sq ft"}
            </span>
          </h4>
        )}
      {product?.ffi_leathersku_url && (
        <Button
          className={
            "btn-secondary btn Ripple-parent also-available-in-leather"
          }
          onClick={() => history?.push("/" + product?.ffi_leathersku_url)}
        >
          <span className="also-available-in-leather-text">
            Also Available in Leather
          </span>
          <Icon icon={"arrowRight2"} />
        </Button>
      )}
      {customer && (
        <div className="price-wrap">
          {!!price && (
            <h2 className={"product-price"}>
              From{": "}
              <Price price={price} type={"fixed"} />
            </h2>
          )}
          <p className={`generic-instock ${isOutOfStock && "out-of-stock"}`}>
            {inStockLogic}
          </p>
        </div>
      )}
      {/* <Specs
                    availableIn={availableIn}
                    mappedSpecs={mappedSpecs}
                    collections={product.collection}
                    productSku={product?.sku}
                    isMobileOrTablet={isMobileOrTablet}
                    product={product}
                  /> */}
      <div className="generic-configure-actions">
        {hasOptions ? (
          <ConfigureProduct
            product={product}
            price={price}
            options={options}
            optionState={optionState}
            optionDispatch={optionDispatch}
            productImage={productImage}
            sku={sku}
            leatherAvailable={leatherAvailable}
            isPelleProvisions={isPelleProvisions}
            ffi_fullyuph={ffi_fullyuph}
            finishOption={finishOption}
            customName={customName}
            finishError={finishError}
            setFinishError={setFinishError}
            dimensionsString={dimensionsString}
            customizationOptionsQueryParameters={downloadTearSheet}
          />
        ) : (
          <>
            {/* <Button
            className={"btn-secondary w-full summary-btn-generic view-build-summary-btn generic-button-font"}
            onClick={() => setBuildSummary(true)}
          >
            {" "}
            <Icon icon={"listDashes"} /> View Build Summary
          </Button> */}
          </>
        )}
      </div>
      <div>
        <Accordion title={"Details"}>
          <p className="generic-description mb-20">
            {product?.additional_info}
          </p>
          <div className="detail-items">
            {separatedDetails.map((group, index) => (
              <div key={index} className="d-flex flex-column">
                {group.map(({ name, value, suffix }) => (
                  <p className="mb-2" key={name}>
                    <span className="generic-description">{name}: </span>
                    <span className="generic-description generic-description-value">
                      {value}
                      {suffix}
                    </span>
                  </p>
                ))}
              </div>
            ))}

            {/* <div className="d-flex flex-column">
              {dimensions?.map((option) => (
                <p key={option.name}>
                  <span className="generic-description">
                    {option.name}
                    {": "}
                  </span>
                  <span className="generic-description generic-description-value">
                    {option.value}
                    {option.suffix}
                  </span>
                </p>
              ))}
              {specifications?.map((option) => (
                <p key={option.name}>
                  <span className="generic-description">
                    {option.name}
                    {": "}
                  </span>
                  <span className="generic-description generic-description-value">
                    {option.value}
                    {option.suffix}
                  </span>
                </p>
              ))}
            </div> */}
            {/* <div className="d-flex flex-column">
              {details?.map((option) => (
                <p key={option.name}>
                  <span className="generic-description">
                    {option.name}
                    {": "}
                  </span>
                  <span className="generic-description generic-description-value">
                    {option.value}
                    {option.suffix}
                  </span>
                </p>
              ))}
            </div> */}
          </div>
        </Accordion>
        {/* TODO add this accordion back when BE pulls data for it */}
        {description?.value?.html && (
          <Accordion title={"Inspiration"}>
            <p
              dangerouslySetInnerHTML={{ __html: description?.value?.html }}
              className="generic-description"
            />
          </Accordion>
        )}
        <Accordion title={"Downloads"}>
          <Downloads
            price={price}
            product={product}
            customer={customer}
            customizationOptionsQueryParameters={downloadTearSheet}
          />
        </Accordion>
      </div>
      {customer && (
        <div className="price-wrap">
          {!!price && (
            <h2 className={"product-price"}>
              From{": "}
              <Price price={price} type={"fixed"} />
            </h2>
          )}
          <p className={`generic-instock ${isOutOfStock && "out-of-stock"}`}>
            {inStockLogic}
          </p>
        </div>
      )}
      <div className="rightSide-actions">
        {isLoggedIn && (
          <Button
            className={"quote-request-button actions-button"}
            onClick={() =>
              isFinishReqDone
                ? quoteRequestButton(
                    isCustom,
                    null,
                    null,
                    null,
                    product?.name,
                    isEdit,
                    product,
                    addItemToCart,
                    product?.sku,
                    calculateOptions,
                    updateItemOnCart,
                    top,
                    base,
                    concatToString,
                    numOfBases
                  )
                : setFinishError(true)
            }
          >
            <Icon icon={"requestQuoteWhite"} /> Add to Quote Request
          </Button>
        )}
        {window.location.pathname.includes("live-edge") ? (
          <></>
        ) : (
          <Button
            className={`favorite-button actions-button ${
              isInFavorites ? "full" : "empty"
            }`}
            onClick={() => {
              setShowModal(false);
              let productWithOptions = { ...product };
              productWithOptions.options = calculateOptions();
              beforeAddToFavorites(productWithOptions, history, location);
              addToWishlistMethod(productWithOptions);
            }}
          >
            {isInFavorites ? (
              <Icon icon={"heartFull"} />
            ) : (
              <Icon icon={"heartGray"} />
            )}
            {isInFavorites ? "Remove from Favorites" : "Add to Favorites"}
          </Button>
        )}
      </div>
      <AlsoAvailable product={product} />
      {buildSummary ? (
        <Summary
          options={mappedOptions}
          setBuildSummary={setBuildSummary}
          buildSummary={buildSummary}
          optionState={optionState}
          size={{
            width: product?.width,
            height: product?.height,
            depth: product?.depth,
          }}
          isTabView
          product={product}
          dimensionsString={dimensionsString}
          customizationOptionsQueryParameters={downloadTearSheet}
          price={price}
        />
      ) : null}
    </div>
  );
};

export default RightSide;
