/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo } from "react";
import Icon from "../../../../../../../../../../assets/icon/icon";
import {
  fabricArray,
  baseFinishArray,
  nailFinishArray,
} from "../../../../../../../../../Custom/haven/data";
import { MDBModal, MDBModalBody, MDBTypography } from "mdbreact";
import { useMediaQuery } from "react-responsive";

const ItemContainer = ({
  fabric,
  indexInArray,
  selectFabric,
  selectedOption,
  title,
  isCustom,
  isFinish,
  sku,
}) => {
  // const [showNotification, setShowNotification] = useState(false);
  // let monogramFinishFound = useMemo(() => {
  //   if (selectedOption) {
  //     let found = monogramFinishArray.find(
  //       (finish) =>
  //         selectedOption?.name.split("-")?.[0]?.includes(finish.name) ||
  //         finish.name.includes(selectedOption?.name.split("-")?.[0])
  //     );
  //     if (found) return true;
  //     return false;
  //   }
  // }, [selectedOption]);

  const [showNotification, setShowNotification] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 600 });
  const positions = [
    {
      left: "10px",
    },
    {
      left: "10px",
    },
    {
      left: "calc((-260px + 66px) / 2)",
    },
    {
      left: "calc((-260px + 66px) / 2)",
    },
    {
      left: "calc((-260px + 66px) / 2)",
    },
    {
      left: "calc(-260px + 66px)",
    },
    {
      left: "calc(-260px + 66px)",
    },
  ];
  const [hidden, setHidden] = useState(true);

  let notVisibleOptions = useMemo(() => {
    if (
      (sku.includes("32L") &&
        (title === "Outside Back" || title === "Inside Back")) ||
      (sku.includes("32T") &&
        (title === "Outside Back" ||
          title === "Inside Back" ||
          title === "Tight Seat")) ||
      ((window.location.pathname.includes("benchmade-ottomans") ||
        window.location.pathname.includes("benchmade-benches") ||
        window.location.pathname.includes("benchmade-round-ottomans") ||
        window.location.pathname.includes("urban-living") ||
        window.location.pathname.includes("senior-living-solutions")) &&
        title === "Tight Seat")
    )
      return true;
  }, [sku]);

  return (
    <>
      <div
        className={`sidebar-item-container ${
          selectedOption?.id === fabric?.id ? "checked" : ""
        }`}
        onClick={() => {
          if (
            (((!fabricArray.find(
              (option) => option.id === fabric.sku?.replace("fabric-", "")
            ) &&
              !baseFinishArray.find((option) => option.id === fabric?.sku) &&
              !nailFinishArray.find(
                (option) => option.name === fabric?.name
              )) ||
              title === "Nail Band") &&
              title !== "Nail Size" &&
              isCustom) ||
            notVisibleOptions
          ) {
            setShowNotification(true);
          }
          selectFabric(fabric);
        }}
      >
        <div
          className={`toolbar-container ${isFinish ? "fabric-toolbar" : ""}`}
          style={
            isMobile
              ? { display: hidden ? "none" : "block" }
              : {
                  left: positions[indexInArray % 7].left,
                  display: hidden ? "none" : "block",
                }
          }
        >
          <img
            data-src={
              title === "Nail Finish"
                ? nailFinishArray.find((finish) => finish.name === fabric?.name)
                    .map
                : fabric.image?.url
            }
            alt={fabric.image?.name}
            className={"lazyload"}
          />
          <p>{fabric?.name}</p>
          {fabric?.grade?.[0] !== "false" && <p>Grade {fabric?.grade?.[0]}</p>}
          {fabric?.pattern?.[0] !== "false" && (
            <p>Pattern {fabric?.pattern?.[0]}</p>
          )}
        </div>
        {/* <div
          className={"arrow"}
          style={{
            display: hidden ? "none" : "block",
          }}
        /> */}
        {/* <div
          className={"arrow-border"}
          style={{
            display: hidden ? "none" : "block",
          }}
        /> */}
        <div
          className={`image-container ${
            (((fabricArray.find(
              (option) => option.id === fabric.sku?.replace("fabric-", "")
            ) ||
              baseFinishArray.find((option) => option.id === fabric.sku) ||
              nailFinishArray.find((option) => option.name === fabric.sku)) &&
              title !== "Nail Band") ||
              title === "Nail Size") &&
            isCustom
              ? "player-display-badge"
              : ""
          }`}
          onMouseEnter={() => setHidden(false)}
          onMouseLeave={() => setHidden(true)}
        >
          <img
            data-src={
              title === "Nail Finish"
                ? nailFinishArray.find((finish) => finish.name === fabric.name)
                    .map
                : fabric.image?.url
            }
            alt={fabric.image?.name}
            className={`lazyload fabric-item ${fabric?.sku?.includes("small") ? "small-image-nailhead" : ""} ${
              selectedOption?.id === fabric?.id ? "selected-image" : ""
            }`}
          />
          {selectedOption?.id === fabric?.id && (
            <Icon icon={"checked"} className={"selected"} />
          )}
        </div>

        <p className="d-none">
          {title !== "Main Fabric"
            ? fabric?.name
            : `${fabric?.pattern[0]} - ${fabric?.name}`}
        </p>
      </div>
      {(showNotification && isCustom && (
        <MDBModal
          className={`notification-modal`}
          backdropClassName={`notification-modal-backdrop`}
          centered
          isOpen={showNotification}
          toggle={() => {
            setShowNotification(!showNotification);
          }}
        >
          <MDBModalBody>
            <MDBTypography tag="p" className={`body-1 text-center`}>
              This selected option is not viewable
            </MDBTypography>
          </MDBModalBody>
        </MDBModal>
      )) || <></>}
    </>
  );
};

export default ItemContainer;
