import Icon from "app/assets/icon/icon";
import DownloadImage from "app/pages/product/components/toolbar/components/downloads/components/downloadImage";
import DownloadTearsheet from "app/pages/product/components/toolbar/components/downloads/components/downloadTearsheet";
import Button from "core/components/button";
import { MDBLink } from "mdbreact";
import React, { Fragment, useMemo } from "react";

const Downloads = ({
  customer,
  customizationOptionsQueryParameters,
  product,
  price,
}) => {
  const downloadIcon = <Icon icon="downloadSimple" className={"loadingIcon"} />;

  const downloadFiles = useMemo(() => {
    const {
      collection_overview_info,
      revit_file,
      assembly_instructions,
      spec_sheet,
    } = product;

    const filesArray = [
      { file: collection_overview_info, name: "Collection Tearsheet" },
      { file: revit_file, name: "Revit File" },
      { file: assembly_instructions, name: "Assembly Instructions" },
      { file: spec_sheet, name: "Detailed Dimensions" },
    ].filter((file) => typeof file.file === "string");

    return filesArray.map((file) => (
      <Fragment key={file.file}>
        <MDBLink
          className="download-link"
          onClick={() => window.open(file.file, "_blank")}
        >
          {downloadIcon}
          <h2 className="nav-link">{file.name}</h2>
        </MDBLink>
      </Fragment>
    ));
  }, [product, downloadIcon]);

  return (
    <div className="downloadsGeneric">
      <DownloadTearsheet
        customer={customer}
        isCustom={false}
        customizationOptionsQueryParameters={
          customizationOptionsQueryParameters
        }
        //   uploadConfiguratorImage={uploadConfiguratorImage}
        id={product?.id}
        name={product?.name}
        price={price}
        icon={downloadIcon}
        title="Product Tearsheet"
        //   player={player}
      />
      {downloadFiles}
      {product?.media_gallery?.length ? (
        <DownloadImage
          image={product?.image}
          sku={product?.sku}
          isCustom={false}
          allImages={true}
          product={product}
          icon={downloadIcon}
        />
      ) : null}
    </div>
  );
};

export default Downloads;
