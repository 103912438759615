import React, { useCallback, useEffect, useMemo, useState } from "react";
import Sidebar from "../selector/components/sidebar";
import { useGetFabricOrFinishFromSku } from "app/pages/product/utils";
import { generateNailSizeOption } from "../selector/selector";
import Dropdown from "../dropdown";
import useCustomer from "app/state/hooks/customer/useCustomer";
import { PRODUCT } from "app/pages/catalog/product/queries";
import { useQuery } from "react-apollo";
import Switch from "app/layout/Switch/index.js";
import { frameNailSizeArray } from "app/pages/Custom/haven/data";
import finishes from "generation/finishes";

const ModalOptionSelection = ({
  option,
  productImage,
  optionDispatch,
  optionState,
  isCustom,
  player,
  isFinish,
  afterFirstOpenOptionState,
  sku,
  leatherAvailable,
  isPelleProvisions,
  finishOption,
  customName,
  isOutdoor,
  optionSku,
  allCategoryOptions,
  setOptionSelected,
  mappedOptions,
  opened,
  sortedAndFilteredMappedOptions,
  setOpened,
  setAllCategoryOptions,
  product,
}) => {
  const customer = useCustomer().isLoggedIn();
  // TODO replace Hardcoded Cushions ID with another solution
  const { data: cushionData } = useQuery(PRODUCT, {
    variables: { filter: { category_id: { eq: 12665 } }, force_default: false },
    fetchPolicy: "cache-and-network",
  });
  const selOption = useGetFabricOrFinishFromSku(optionSku);
  const filteredAllCategoryOptions = useMemo(
    () =>
      allCategoryOptions?.filter(
        (opt) =>
          !["finish", "nail band", "throw pillow decorative cord"].includes(
            opt.title.toLowerCase()
          )
      ),
    [allCategoryOptions]
  );

  const nailsSelection = useMemo(
    () => option?.category_options === "Nailhead Trim Options",
    [option]
  );
  const [showAllOptions, setShowAllOptions] = useState(nailsSelection);
  useEffect(() => {
    setShowAllOptions(nailsSelection);
  }, [nailsSelection]);

  const selectedOption = useMemo(() => {
    if (option?.title?.includes("Seat Cushion")) {
      const optionValue = option?.value?.find(
        (val) => val.option_type_id === optionSku
      );
      const optionProduct = cushionData?.products?.items?.find(
        (product) => product?.name === optionValue?.title
      );
      return {
        name: optionValue.title,
        id: optionValue.option_type_id,
        image: { url: optionProduct?.small_image?.url },
        grade: ["false"],
        pattern: ["false"],
        map: optionValue.map,
        icon: optionProduct?.small_image?.url,
        size: optionValue.size,
        url_key: optionProduct?.url_key,
      };
    }
    if (option?.title === "Nailhead") {
      if (optionSku) {
        const nailhead = finishes
          ?.find((finish) => finish.name === "Nail Finish")
          ?.products?.items?.find((item) => optionSku?.includes(item?.id));
        const nailSize = frameNailSizeArray?.find((size) =>
          optionSku?.includes(size?.size)
        );
        const selectObj = {
          ...nailhead,
          nailSize: nailSize,
          size: nailSize?.size,
          name: nailhead?.name + " " + nailSize?.name,
          nailhead: nailhead,
          id: nailhead?.id + "_" + nailSize?.size,
          sku: nailhead?.sku + "_" + nailSize?.size,
        };
        return selectObj;
      } else return null;
    }
    return !selOption && typeof optionSku === "number"
      ? generateNailSizeOption(
          option?.value?.find((val) => val.option_type_id === optionSku)
        )
      : selOption;
  }, [selOption, optionSku, option, cushionData]);
  const saveSelectionFn = useCallback(() => {
    const currentMainOption =
      option?.title === "Finish"
        ? -1
        : sortedAndFilteredMappedOptions.findIndex(
            (optionM) => opened[optionM]
          );
    let openedState = { ...opened };
    openedState = Object.keys(openedState).forEach((key) => {
      openedState[key] = false;
    });
    if (!showAllOptions || nailsSelection) {
      if (sortedAndFilteredMappedOptions[currentMainOption + 1]) {
        setOptionSelected(
          mappedOptions[
            sortedAndFilteredMappedOptions[currentMainOption + 1]
          ][0]
        );
        setOpened({
          ...openedState,
          [sortedAndFilteredMappedOptions[currentMainOption + 1]]: true,
        });
        setAllCategoryOptions(
          mappedOptions[sortedAndFilteredMappedOptions[currentMainOption + 1]]
        );
      }
    } else {
      const currentIndex = filteredAllCategoryOptions?.findIndex(
        (opt) => opt.title === option?.title
      );
      if (currentIndex + 1 === filteredAllCategoryOptions?.length) {
        if (sortedAndFilteredMappedOptions[currentMainOption + 1]) {
          setOptionSelected(
            mappedOptions[
              sortedAndFilteredMappedOptions[currentMainOption + 1]
            ][0]
          );
          setOpened({
            ...openedState,
            [sortedAndFilteredMappedOptions[currentMainOption + 1]]: true,
          });
          setAllCategoryOptions(
            mappedOptions[sortedAndFilteredMappedOptions[currentMainOption + 1]]
          );
        }
      } else {
        setOptionSelected(filteredAllCategoryOptions[currentIndex + 1]);
      }
    }
  }, [
    showAllOptions,
    option,
    opened,
    mappedOptions,
    nailsSelection,
    filteredAllCategoryOptions,
    sortedAndFilteredMappedOptions,
  ]);

  if (option?.category_options === "Additional Options") {
    return (
      <div className="option-dropdown-selection">
        {allCategoryOptions?.map((opt) =>
          opt?.category_options === "Additional Options" ? (
            <Dropdown
              key={opt.option_id}
              selectedOption={
                !opt.value[0].price
                  ? {
                      label: `${opt.value[0].title} ${
                        customer ? "(standard)" : ""
                      }`,
                      value: opt.value[0].option_type_id,
                    }
                  : false
              }
              option={opt}
              optionDispatch={optionDispatch}
              optionSku={
                optionState[opt.title.toLowerCase().replace(/ /g, "_")]
              }
            />
          ) : (
            <Switch
              key={`checkBox-option-${opt.option_id}`}
              className={"checkbox-option"}
              //FIXME: When FF Wants
              // label={`${option.title} ${
              //   customer ? " + $" + option.value[0].price.toFixed(2) : ""
              // }`}
              label={opt.title}
              checked={
                optionState[opt.title.toLowerCase().split(" ").join("_")] ||
                false
              }
              onChange={() =>
                optionDispatch({
                  type: "setAttribute",
                  attribute: opt.title.toLowerCase().split(" ").join("_"),
                  payload: !optionState[
                    opt.title.toLowerCase().split(" ").join("_")
                  ]
                    ? opt.value[0].option_type_id
                    : false,
                })
              }
            />
          )
        )}
      </div>
    );
  }
  return (
    <div className="modal-option-selection">
      {option?.category_options === "Nailhead Options" &&
        product?.nailhead_application && (
          <p className="generic-description">
            Nailhead application:{" "}
            <span className="generic-description-value">
              {product?.nailhead_application}
            </span>
          </p>
        )}
      {filteredAllCategoryOptions?.length > 1 && !nailsSelection && (
        <div
          role="button"
          className="show-additional-trigger"
          onClick={() => setShowAllOptions(!showAllOptions)}
          onKeyPress={() => setShowAllOptions(!showAllOptions)}
          tabIndex={-1}
        >
          {showAllOptions ? "Hide" : "Show"} Additional{" "}
          {option?.category_options !== "Upholstery Options"
            ? option?.category_options
            : `${product?.available_in_leather ? "Leather" : "Fabric"} Options`}
        </div>
      )}
      {showAllOptions && (
        <div className="tabs-container">
          {filteredAllCategoryOptions?.map((opt) => (
            <div
              onClick={() => setOptionSelected(opt)}
              key={opt?.title}
              className={`tabs-option ${
                opt?.title === option?.title ? "active-tabs-option" : ""
              }`}
              onKeyDown={() => setOptionSelected(opt)}
              role="button"
              tabIndex={-1}
            >
              {opt?.title !== "Main Fabric"
                ? opt?.title
                : `Main ${
                    product?.available_in_leather ? "Leather" : "Fabric"
                  }`}
            </div>
          ))}
        </div>
      )}
      <Sidebar
        visible={true}
        setVisible={() => {}}
        option={option}
        selectedOption={selectedOption}
        productImage={productImage}
        optionDispatch={optionDispatch}
        key={option?.id}
        isCustom={isCustom}
        player={player}
        optionState={optionState}
        isFinish={isFinish}
        afterFirstOpenOptionState={afterFirstOpenOptionState}
        sku={sku}
        leatherAvailable={leatherAvailable}
        isPelleProvisions={isPelleProvisions}
        finishOption={finishOption}
        customName={customName}
        isOutdoor={isOutdoor}
        isTabView={true}
        saveSelectionFn={saveSelectionFn}
        cushionData={cushionData}
        mappedOptions={mappedOptions}
        product={product}
      />
    </div>
  );
};

export default ModalOptionSelection;
