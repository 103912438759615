import {
  MDBBox,
  MDBCol,
  MDBContainer,
  MDBFooter,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBTypography,
  MDBBtn,
} from "mdbreact";
import React, { useState } from "react";
import Icon from "app/assets/icon/icon";
import ProductDetails from "./productDetails";
import Options from "app/pages/product/components/options";

const ConfigureProductModal = ({
  product,
  price,
  options,
  optionDispatch,
  productImage,
  sku,
  leatherAvailable,
  isPelleProvisions,
  ffi_fullyuph,
  finishOption,
  customName,
  finishError,
  optionState,
  setFinishError,
  priceDispatch,
  isOpen,
  setOpen,
  dimensionsString,
  customizationOptionsQueryParameters
}) => {
  return (
    <MDBModal
      isOpen={isOpen}
      toggle={() => setOpen(!isOpen)}
      id={"configure-product-modal-id"}
      className="configureProductModal"
    >
      <MDBModalHeader className="configureProductModal-header">
        <MDBRow style={{ position: "static" }}>
          <MDBBox>
            <MDBTypography
              tag="h2"
              variant="display-2"
              className="font-weight-light text-center"
            >
              Configuration Options
            </MDBTypography>
          </MDBBox>
        </MDBRow>
        <MDBBox
          className="ml-4 pl-2 position-absolute modal-title-search cursor-pointer"
          onClick={() => setOpen(!isOpen)}
          style={{ right: "44px", top: "28px" }}
        >
          <Icon icon="greyX" />
        </MDBBox>
      </MDBModalHeader>
      <MDBModalBody className="pt-2 pb-0 bg-white">
        <div className="d-flex justify-content-between h-100">
          <div className="w-100 position-relative">
            <Options
              price={price}
              product={product}
              options={options}
              optionState={optionState}
              optionDispatch={optionDispatch}
              productImage={productImage}
              sku={sku}
              leatherAvailable={leatherAvailable}
              isPelleProvisions={isPelleProvisions}
              ffi_fullyuph={ffi_fullyuph}
              finishOption={finishOption}
              customName={customName}
              finishError={finishError}
              setFinishError={setFinishError}
              priceDispatch={priceDispatch}
              isTabsView={true}
            />
          </div>
          <ProductDetails
            product={product}
            price={price}
            options={options}
            optionState={optionState}
            dimensionsString={dimensionsString}
            setModalOpen={setOpen}
            customizationOptionsQueryParameters
          />
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

export default ConfigureProductModal;
