export const staticLinks = [
  // {
  //   name: "What`s New",
  //   id: null,
  //   image: null,
  //   children: [],
  //   position: 301,
  //   url_path: "what-s-new",
  //   include_in_menu: 1,
  //   hero_image: 0,
  //   isLoggedInOnly: false,
  //   description: 0,
  // },
  {
    name: "Inspirations",
    id: null,
    image: null,
    children: [],
    position: 300,
    url_path: "inspirations",
    include_in_menu: 1,
    hero_image: 0,
    isLoggedInOnly: false,
    description: 0,
  },
  {
    name: "About Us",
    id: null,
    image: null,
    children: [
      {
        name: "Our History",
        id: null,
        image: null,
        children: [],
        position: 301,
        url_path: "timeline",
        include_in_menu: 1,
        hero_image: 0,
        isLoggedInOnly: false,
        description: 0,
        parent: "About Us",
      },
      {
        name: "Mission / Vision / Values",
        id: null,
        image: null,
        children: [],
        position: 301,
        url_path: "mission-vision-values",
        include_in_menu: 1,
        hero_image: 0,
        isLoggedInOnly: false,
        description: 0,
        parent: "About Us",
      },
    ],
    position: 301,
    url_path: "company-story",
    include_in_menu: 1,
    hero_image: 0,
    isLoggedInOnly: false,
    description: 0,
  },
  {
    name: "Contact a rep",
    id: null,
    image: null,
    children: [],
    position: 301,
    url_path: "sales-representative",
    include_in_menu: 1,
    hero_image: 0,
    isLoggedInOnly: false,
    description: 0,
  },
  {
    name: "Store Locator",
    id: null,
    image: null,
    children: [],
    position: 301,
    url_path: "where-to-buy",
    include_in_menu: 1,
    hero_image: 0,
    isLoggedInOnly: false,
    description: 0,
  },
];
