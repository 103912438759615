import gql from "graphql-tag";
import React from "react";
import { useQuery } from "react-apollo";
import { Link } from "react-router-dom";

const AlsoAvailable = ({ product }) => {
  const alsoAvailableSkus = product?.ffi_alsoavail?.split(",");
  const ALSO_AVAILABLE_SKUS = gql`
    query alsoAvailableProducts($alsoAvailableSkus: [String], $pageSize: Int) {
      products(
        filter: { sku: { in: $alsoAvailableSkus } }
        pageSize: $pageSize
      ) {
        items {
          name
          ffi_name
          short_description {
            html
          }
          url_key
          sku
          thumbnail {
            url
          }
          image {
            url
          }
          ffi_extdesc2
        }
      }
    }
  `;

  const { data } = useQuery(ALSO_AVAILABLE_SKUS, {
    variables: { alsoAvailableSkus: alsoAvailableSkus, pageSize: 3 },
    skip: !alsoAvailableSkus || !alsoAvailableSkus?.length,
  });

  if (data?.products?.items?.length > 0) {
    return (
      <div className="also-available">
        <h3 className="also-available-title">Also Available</h3>
        <div className="also-available-skus">
          {data?.products?.items.map((item) => {
            return (
              <Link
                to={`/${item?.url_key}`}
                className="also-available-sku"
                key={item?.sku}
              >
                <img
                  src={item?.image?.url}
                  alt={item?.name}
                  className="also-available-sku-img"
                />
                <h4>
                  {item?.ffi_name || item?.name} {item?.short_description?.html}
                </h4>
                <p>{item?.ffi_extdesc2}</p>
                <p>{item?.sku}</p>
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
  return <></>;
};

export default AlsoAvailable;
