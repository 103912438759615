import React from "react";
import HeaderStateContainer from "./header-state-container";
import _ from "lodash";
import { staticLinks } from "./staticLinks";
import SkeletonHeader from "./skeleton-header";
const sortTree = (category) => {
  if (!_.isUndefined(category.children)) {
    category.children.forEach((subCategory) => {
      sortTree(subCategory);
    });
    sortChildren(category);
  }
};

const sortChildren = (category) => {
  category.children.sort((a, b) => {
    return a.position - b.position;
  });
};

const addStaticLinks = (category) => {
  staticLinks.forEach((staticLink) => {
    let addFlag = category.children.find((cat) => {
      return cat.name === staticLink.name || cat.parent === staticLink.name;
    });
    if (!addFlag) {
      category.children.push(staticLink);
    }
  });
};

const HeaderDataContainer = (props) => {
  if (!_.isUndefined(props?.data?.categoryList?.[0])) {
    sortTree(props.data.categoryList[0]);
    addStaticLinks(props.data.categoryList[0]);
  } else {
    return <SkeletonHeader />;
  }

  return <HeaderStateContainer {...props} />;
};

export default HeaderDataContainer;
