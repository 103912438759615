import React, { useMemo } from "react";
import Icon from "../../../../../../../../assets/icon/icon";
import { MDBLink } from "mdbreact";
import themeConfiguration from "../../../../../../../../../config/themeConfiguration";
import axios from "axios";
import FileSaver from "file-saver";
import { useDownloadImages } from "app/helpers/downloadImages";

export const CreateDownloadLink = async (urls, sku) => {
  const url =
    themeConfiguration.magento_url +
    "rest/V1/jola-filedownloader/downloadFiles";
  let filesArray = [];
  filesArray = [
    {
      url: urls,
    },
  ];
  const rawRequest = JSON.stringify({
    type: "images",
    name: sku,
    files: filesArray,
  });
  const config = {
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: rawRequest,
  };

  axios(config)
    .then((data) => {
      if (data.status === 200) {
        FileSaver.saveAs(
          JSON.parse(data.data).base64,
          JSON.parse(data.data).image_name
        );
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

const DownloadImage = ({
  player,
  image,
  sku,
  isCustom,
  allImages = false,
  product = false,
  icon = <Icon icon="downloadSmall" />
}) => {
  let imageUrl = useMemo(
    () =>
      image?.url ||
      `${themeConfiguration.magento_url}media/catalog/product/placeholder/default/placeholder.png`,
    [image]
  );
  const { downloadImagesMethod } = useDownloadImages();
  const save3DImage = async (e, backupImage, sku) => {
    return new Promise((resolve, reject) => {
      if (player) {
        let initColor = player.scene.background;
        let newColor = "#ffffff"; //change for different color
        player.scene.background.set(newColor);

        let initPosition = player.getCameraPosition();
        // player.setCameraPosition({ x: -30, y: 10, z: 50 });

        player.shadowPlane.visible = false;
        player.plane.visible = false;

        player.update();

        e.stopPropagation();
        new Promise((resolve) => {
          let dataUrl = undefined;
          try {
            dataUrl =
              player.container.getElementsByTagName("canvas")[0].toDataURL() ||
              undefined;
          } catch (e) {
            resolve(null);
          }
          resolve(dataUrl);
        }).then((blob) => {
          if (blob) {
            CreateDownloadLink(blob, sku).catch((e) => reject(e));
          }
        });
        player.scene.background.set(initColor);
        player.setCameraPosition(initPosition);
        player.shadowPlane.visible = true;
        player.plane.visible = true;
      } else {
        CreateDownloadLink(backupImage, sku).catch((e) => reject(e));
      }
    });
  };

  return (
    <div className="download-link">
      {icon}
      <MDBLink
        className="d-inline-block ml-1 p-0 link-text-3"
        onClick={(event) => {
          if (allImages) {
            downloadImagesMethod([
              {
                product_sku: sku,
              },
            ]);
          } else {
            if (isCustom) {
              if (imageUrl) {
                save3DImage(event, imageUrl, sku).catch((e) =>
                  console.error(e)
                );
                event.preventDefault();
              }
            } else {
              if (image?.url) {
                CreateDownloadLink(imageUrl, sku).catch((e) =>
                  console.error(e)
                );
              }
              event.preventDefault();
            }
          }
        }}
      >
        {!allImages
          ? window.location.pathname.includes("build-your-own")
            ? "Build Image"
            : "Image"
          : "All images (zip)"}
      </MDBLink>
    </div>
  );
};

export default DownloadImage;
