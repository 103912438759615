import { MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import CmsBlock from "app/layout/cmsBlock";

const MissionVisionValues = () => {
  return (
    <MDBContainer className="m-0 hero-banner mission-hero-wrapper" fluid>
      <CmsBlock id={"mission-hero"} />
      <MDBRow className="flex justify-content-center">
        <CmsBlock id={"mission_vision_values"} />
      </MDBRow>
    </MDBContainer>
  );
};

export default MissionVisionValues;
