import _ from "lodash";
import { MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import AdditionalInformations from "./AdditionalInformations";
import { mainFabricName, OptionSection, OptionSelector } from "./OptionSection";
import { CustomPriceContext } from "../customPriceContext";
import useDeepCompareEffect from "use-deep-compare-effect";
const upholsteryName = "Upholstery Options";
const pillowUpholsteryName = "Pillow Upholstery Options";
const viewMoreUpholsteryName = "View More Upholstery Applications";

/**
 *
 * @type {React.FC<*>}
 */
export const Options = ({
  customizationProps,
  isCustom,
  productImage,
  defaults,
  fabricsSelections,
  frame,
  fabricOrFinishesSelectedOptions,
  viewMoreOptionsPrice,
  viewMorePdpPrice,
  setViewMorePdpPrice,
  viewMoreOptions,
  setViewMoreOptions,
  setFabricOrFinishSelectedOptions,
  nailData,
  allowedFinishes,
  categories,
  isAddingToCart,
  queryPathFabrics,
  checkBoxOptions,
  setCheckBoxOptions,
  dropdownOptions,
  setDropdownOptions,
  customizationOptionPrice,
  upholsteryOptionsFabrics,
  customer,
  foundFabrics,
  productData,
}) => {
  let opts = customizationProps.options;
  const history = useHistory();

  const {
    height,
    width,
    depth,
    seat_height,
    inside_back_height,
    inside_seat_depth,
    inside_seat_width,
    arm_height,
    materials,
    fabric_base_yardage,
    nailhead_options,
    nailhead_application,
    standard_seat,
    standard_back,
    weight,
    spring_construction,
    back_conctruction,
    seat_construction,
    country_of_origin,
    cube,
  } = productData;

  const {
    pillowDropdownSelector,
    setPillowDropdownSelector,
    viewMoreDropdownSelector,
    setViewMoreDropdownSelector,
  } = useContext(CustomPriceContext);

  const [additionalInfo, setAdditionalInfo] = useState([
    // {
    //   title: "Description",
    //   isOpen: false,
    // },
    {
      title: "Details",
      isOpen: false,
      details: [
        { title: "Material", value: materials },
        // { title: "Fabric base yardage", value: fabric_base_yardage },
        { title: "COM", value: fabric_base_yardage },
        {
          title: "Nailhead options",
          value: nailhead_options
            ? nailhead_options === 1
              ? "Yes"
              : "No"
            : undefined,
        },
        { title: "Nailhead application", value: nailhead_application },
      ],
    },
    {
      title: "Specifications",
      isOpen: false,
      specifications: [
        { title: "Standard seat", value: standard_seat },
        { title: "Standard back", value: standard_back },
        { title: "Weight", value: weight },
        { title: "Spring construction", value: spring_construction },
        { title: "Seat construction", value: seat_construction },
        { title: "Back construction", value: back_conctruction },
        { title: "Country of origin", value: country_of_origin },
        { title: "Cube", value: cube },
      ],
    },
    {
      title: "Dimensions (in)",
      isOpen: false,
      dimensions: [
        { title: "Width", value: width },
        { title: "Height", value: height },
        { title: "Depth", value: depth },
        { title: "Seat height", value: seat_height },
        { title: "Inside seat depth", value: inside_seat_depth },
        { title: "Inside seat width", value: inside_seat_width },
        { title: "Inside seat height", value: inside_back_height },
        { title: "Arm height", value: arm_height },
      ],
    },
  ]);

  /**
   * @type {Record<string, any>}
   */
  let cats = {};
  const isMobile = useMediaQuery({ maxWidth: 992 });
  opts.forEach((/** @type {any} */ o) => {
    if (
      o.__typename === "CustomizableCheckboxOption" &&
      o.title !== "Nailhead Trim Option"
    ) {
      if ("More Options" in cats) {
        cats["More Options"].push(o);
      } else {
        cats["More Options"] = [];
        cats["More Options"].push(o);
      }
    } else {
      if (o.category_options) {
        if (!cats[o.category_options]) {
          cats[o.category_options] = [o];
        } else {
          cats[o.category_options].push(o);
        }
      }
    }
  });

  _.fromPairs(Object.entries(cats).reverse());
  // @TODO: This is very bad, it was implemented as a quick fix, fix it
  let sortedCats = {};
  if (cats["Upholstery Options"]) {
    sortedCats["Upholstery Options"] = cats["Upholstery Options"];
  }
  if (cats["Pillow Upholstery Options"]) {
    sortedCats["Pillow Upholstery Options"] = cats["Pillow Upholstery Options"];
  }
  if (cats["Nailhead Trim Options"]) {
    cats["Nailhead Trim Options"].forEach((opt) => {
      if (opt.title.toLowerCase().includes("nail size")) {
        opt.__typename = "CustomizableFieldOption";
      }
    });
    sortedCats["Nailhead Trim Options"] = cats["Nailhead Trim Options"];
  }
  if (cats["Additional Options"]) {
    sortedCats["Additional Options"] = cats["Additional Options"];
  }
  if (cats["More Options"]) {
    sortedCats["More Options"] = cats["More Options"];
  }

  cats = sortedCats;

  const catNames = useMemo(() => {
    const keys = Object.keys(cats);
    const filteredCatNames = keys.filter((key) => key !== upholsteryName);

    return filteredCatNames.length < keys.length
      ? [upholsteryName, ...filteredCatNames]
      : filteredCatNames;
  }, [cats]);

  let finishOption = null;
  if (cats?.["Upholstery Options"]) {
    finishOption = cats?.["Upholstery Options"].filter((o) => {
      return (
        o.title === "Finish" &&
        !history.location.pathname.includes("build-your-own/monogram")
      );
    })[0];

    let finishIndex = cats["Upholstery Options"].indexOf(finishOption);
    cats["Upholstery Options"].splice(finishIndex, 1);
  }

  useDeepCompareEffect(() => {
    const localPillowDropdownSelector = JSON.parse(
      JSON.stringify(pillowDropdownSelector)
    );
    catNames.forEach((catName) => {
      const categoryOptions = cats[catName];

      const isViewMoreUpholstery = viewMoreUpholsteryName === catName;
      const options =
        upholsteryName === catName
          ? [
              cats[upholsteryName].find(
                (/** @type {any} */ option) => option.title === mainFabricName
              ),
            ]
          : isViewMoreUpholstery
          ? cats[upholsteryName].filter((/** @type {any} */ option) => {
              return option.title !== mainFabricName;
            })
          : categoryOptions;
      if (catName.toLowerCase().indexOf("pillow") !== -1) {
        options.forEach((option) => {
          if (
            option.title.toLowerCase() === "throw pillow contrast welt" ||
            option.title.toLowerCase() === "throw pillow decorative cord" ||
            option.title.toLowerCase() === "throw pillow fringe"
          ) {
            localPillowDropdownSelector.throw_pillow[
              option.title.toLowerCase().split(" ").join("_")
            ] = false;
          } else if (
            option.title.toLowerCase() === "kidney pillow contrast welt" ||
            option.title.toLowerCase() === "kidney pillow decorative cord" ||
            option.title.toLowerCase() === "kidney pillow fringe"
          ) {
            localPillowDropdownSelector.kidney_pillow[
              option.title.toLowerCase().split(" ").join("_")
            ] = false;
          }
        });
      }
    });

    setTimeout(() => {
      let found = false;
      Object.keys(pillowDropdownSelector).forEach((key) => {
        if (Object.keys(pillowDropdownSelector[key]).length > 0) {
          found = true;
        }
      });
      if (!found) {
        setPillowDropdownSelector(localPillowDropdownSelector);
      }
    }, 0);
  }, [catNames, viewMoreDropdownSelector, pillowDropdownSelector]);

  return (
    <MDBCol
      style={{
        width: !isCustom && !isMobile ? "47rem" : "100%",
        marginTop: isMobile ? "0" : "2rem",
        padding: isCustom && 0,
      }}
    >
      {finishOption && (
        <MDBRow className="py-2 pl-md-3 my-3 options-selections">
          <MDBCol size="12" lg="3" className="py-3">
            <MDBTypography tag="h2" className="body-2 fwlight">
              {finishOption.title}
            </MDBTypography>
          </MDBCol>
          <MDBCol className="cursor-pointer pl-md-2 d-flex align-items-center">
            <OptionSelector
              opt={finishOption}
              customizationProps={customizationProps}
              productImage={productImage}
              defaults={defaults}
              frame={frame}
              fabricOrFinishesSelectedOptions={fabricOrFinishesSelectedOptions}
              allowedFinishes={allowedFinishes}
              isCustom={isCustom}
              nailData={nailData}
              viewMorePdpPrice={viewMorePdpPrice}
              setViewMorePdpPrice={setViewMorePdpPrice}
              viewMoreOptions={viewMoreOptions}
              setViewMoreOptions={setViewMoreOptions}
              setFabricOrFinishSelectedOptions={
                setFabricOrFinishSelectedOptions
              }
              optionClassNames="mx-auto"
              categories={categories}
              isAddingToCart={isAddingToCart}
              queryPathFabrics={queryPathFabrics}
              checkBoxOptions={checkBoxOptions}
              setCheckBoxOptions={setCheckBoxOptions}
              dropdownOptions={dropdownOptions}
              setDropdownOptions={setDropdownOptions}
              customizationOptionPrice={customizationOptionPrice}
              upholsteryOptionsFabrics={upholsteryOptionsFabrics}
              customer={customer}
              foundFabrics={foundFabrics}
            />
          </MDBCol>
        </MDBRow>
      )}
      {catNames.map((catName, index) => {
        const categoryOptions = cats[catName];

        const isViewMoreUpholstery = viewMoreUpholsteryName === catName;

        const options =
          upholsteryName === catName
            ? [
                cats[upholsteryName].find(
                  (/** @type {any} */ option) => option.title === mainFabricName
                ),
              ]
            : isViewMoreUpholstery
            ? cats[upholsteryName].filter((/** @type {any} */ option) => {
                return option.title !== mainFabricName;
              })
            : categoryOptions;

        let displayProps = {
          title: isViewMoreUpholstery ? viewMoreUpholsteryName : catName,
          options: options,
          customizationProps,
          isCustom,
          isOpen: false,
          fabricsSelections: [
            upholsteryName,
            viewMoreUpholsteryName,
            pillowUpholsteryName,
          ].includes(catName)
            ? fabricsSelections
            : null,
          titleClassName: isViewMoreUpholstery
            ? "cursor-pointer fwlight text-default"
            : "",
          fabricOrFinishesSelectedOptions,
          setFabricOrFinishSelectedOptions,
          subOptions:
            catName === "Upholstery Options" &&
            cats[upholsteryName].filter((/** @type {any} */ option) => {
              return option.title !== mainFabricName;
            }),
        };

        if (index === 0) {
          displayProps.isOpen = true;
        }
        return (
          <OptionSection
            key={catName}
            viewMorePdpPrice={viewMorePdpPrice}
            {...displayProps}
            queryPathFabrics={queryPathFabrics}
            productImage={productImage}
            defaults={defaults}
            frame={frame}
            nailData={nailData}
            allowedFinishes={allowedFinishes}
            viewMoreOptionsPrice={viewMoreOptionsPrice}
            setViewMorePdpPrice={setViewMorePdpPrice}
            viewMoreOptions={viewMoreOptions}
            setViewMoreOptions={setViewMoreOptions}
            categories={categories}
            isAddingToCart={isAddingToCart}
            checkBoxOptions={checkBoxOptions}
            setCheckBoxOptions={setCheckBoxOptions}
            dropdownOptions={dropdownOptions}
            setDropdownOptions={setDropdownOptions}
            customizationOptionPrice={customizationOptionPrice}
            upholsteryOptionsFabrics={upholsteryOptionsFabrics}
            customer={customer}
            foundFabrics={foundFabrics}
            pillowDropdownSelector={pillowDropdownSelector}
            setPillowDropdownSelector={setPillowDropdownSelector}
            viewMoreDropdownSelector={viewMoreDropdownSelector}
            setViewMoreDropdownSelector={setViewMoreDropdownSelector}
          />
        );
      })}
      <MDBCol
        className={`need-assistance-wrapper`}
        style={{ paddingLeft: !isCustom ? "1.5rem" : 0 }}
      >
        <MDBTypography
          tag="p"
          className={`body-1 text-med-gray mt-5 w-100 need-assistance-text ${
            isCustom && "custom"
          }`}
        >
          {"Need assistance or have a question? Chat with "}
          <Link
            to="/contact"
            target="_blank"
            style={{
              fontSize: "1.6rem",
            }}
          >
            Customer Service
          </Link>
        </MDBTypography>
      </MDBCol>
      {history.location.pathname.includes("/build-your-own") &&
        additionalInfo.map((info) => (
          <AdditionalInformations
            key={info.title}
            info={info}
            setAdditionalInfo={setAdditionalInfo}
            additionalInfo={additionalInfo}
          />
        ))}
    </MDBCol>
  );
};
