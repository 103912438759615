import React from "react";
import CmsBlock from "app/layout/cmsBlock";
import { MDBContainer } from "mdbreact";

const Timeline = () => {
    return ( <div className="timeline-wrap">
    <MDBContainer className="m-0 hero-banner" fluid>
    <CmsBlock id="timeline-hero" />
    </MDBContainer>
    <CmsBlock id="timeline1" />
    <CmsBlock id="timeline2" />
    <CmsBlock id="timeline3" />
    <CmsBlock id="timeline4" />
    <CmsBlock id="timeline5" />
    <CmsBlock id="timeline6" />
    <CmsBlock id="timeline7" />
    </div> );
}
 
export default Timeline;