import React, { useCallback, useMemo } from "react";
import Icon from "app/assets/icon/icon";
import { withRouter } from "react-router-dom";
import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { showingDataLogic } from "./helper";
import { set } from "lodash";
import Button from "core/components/button";
import DownloadTearsheet from "app/pages/product/components/toolbar/components/downloads/components/downloadTearsheet";
import useCustomer from "app/state/hooks/customer/useCustomer";

const Summary = ({
  options,
  setBuildSummary,
  buildSummary,
  history,
  optionState,
  size,
  isTabView,
  product,
  dimensionsString,
  customizationOptionsQueryParameters,
  price,
}) => {
  const downloadIcon = (
    <Icon icon="Download" className={"download-icon-summary"} />
  );
  const customer = useCustomer().isLoggedIn();
  const isRecliner = useMemo(() => {
    return optionState?.build_your_own_options &&
      JSON.parse(optionState?.build_your_own_options)
        ?.frame?.name?.toLowerCase()
        ?.includes("recliner")
      ? true
      : false;
  }, [optionState]);

  const isMainFabric = useMemo(() => {
    return options?.["upholstery_options"]?.find(
      (option) =>
        option.title === "Main Fabric" || option?.option_title === "Main Fabric"
    );
  }, [options]);

  let optionsUpdated = useMemo(() => {
    return [
      options?.["upholstery_options"]?.find(
        (option) =>
          option?.title === "Finish" || option?.option_title === "Finish"
      ) &&
      (isMainFabric || options?.["upholstery_options"]?.length >= 1)
        ? {
            finish_options: [
              options?.["upholstery_options"]?.find(
                (option) =>
                  option?.title === "Finish" ||
                  option?.option_title === "Finish"
              ),
            ],
          }
        : null,
      options?.["upholstery_options"] &&
      (isMainFabric ||
        (options?.["upholstery_options"]?.length >= 1 &&
          options?.["upholstery_options"]?.find(
            (option) => option?.title !== "Finish"
          )))
        ? {
            upholstery_options: options?.["upholstery_options"]?.filter(
              (option) =>
                option?.title !== "Finish" && option?.option_title !== "Finish"
            ),
          }
        : null,
      options?.["pillow_upholstery_options"]
        ? { pillow_upholstery_options: options?.["pillow_upholstery_options"] }
        : null,
      options?.["nailhead_trim_options"]
        ? { nailhead_trim_options: options?.["nailhead_trim_options"] }
        : null,
      options?.["additional_options"]
        ? { additional_options: options?.["additional_options"] }
        : null,
      options?.["view_more_options"]
        ? { view_more_options: options?.["view_more_options"] }
        : null,
    ]?.filter((item) => item !== null);
  }, [options]);


  const isMonogram = useMemo(() => {
    if (optionState?.build_your_own_options?.includes("monogram")) return true;
    return false;
  }, [optionState]);

  const BYO = useMemo(() => {
    const build_your_own_options = optionState?.build_your_own_options
      ? JSON.parse(optionState?.build_your_own_options)
      : null;
    if (!build_your_own_options) return null;
    for (const key in build_your_own_options) {
      if (key === "powerOption" && !isRecliner)
        delete build_your_own_options["powerOption"];
      else if (key === "powerOption" && isRecliner) {
        delete build_your_own_options["base"];
        delete build_your_own_options["seatAndBack"];
      }

      if (build_your_own_options[key]?.modelImage)
        build_your_own_options[key].image =
          build_your_own_options[key].modelImage;

      if ("model" in build_your_own_options) {
        if (!build_your_own_options["model"]?.contrastDrawers)
          delete build_your_own_options?.contrastDrawers;
        if (!build_your_own_options["model"]?.striping)
          delete build_your_own_options?.striping;
      }
      // return option;
    }
    return build_your_own_options;
  }, [optionState]);

  const findValue = useCallback(
    (option, key) => showingDataLogic(optionState, option, key, optionsUpdated),
    [optionState]
  );

  let showData = useCallback((value) => {
    if (value) {
      return value?.image
        ? `${value?.name} ${
            value?.pattern && value?.pattern?.[0] !== "false"
              ? "Pattern " + value?.pattern?.[0]
              : ""
          } ${
            value?.grade && value?.grade?.[0] !== "false"
              ? " Grade " + value?.grade?.[0]
              : ""
          }`
        : value?.option_value_title
        ? value?.option_value_title
        : value?.title;
    }
    return null;
  }, []);

  const RenderOption = ({ option, index }) => {
    const isOttoman = BYO?.frame.name === "Ottoman";
    const parentNames = ["Base", "Size", "Frame"];
    const shouldDisplay = parentNames.includes(BYO?.[option]?.parentName);
    const imageSrc =
      BYO?.[option]?.image ||
      BYO?.[option]?.imageLeft ||
      BYO?.[option]?.imageRight;
    const displayName = BYO?.[option]?.relatedsku
      ? `${BYO?.[option]?.size} ${BYO?.[option]?.finish}`
      : BYO?.[option]?.name;

    if (!BYO?.[option]) return null;

    return (
      <div className={`d-flex ${option}`} key={index}>
        {shouldDisplay && (
          <p style={{ textTransform: "capitalize" }}>
            {option === "contrastDrawers" ? BYO?.[option]?.parentName : option}
          </p>
        )}
        {shouldDisplay && (
          <div className={"d-flex"}>
            {imageSrc && <img src={imageSrc} alt={BYO?.[option]?.name} />}
            <p className="value">{displayName}</p>
          </div>
        )}
        <div></div>
      </div>
    );
  };

  return (
    <MDBModal
      isOpen={buildSummary}
      toggle={() => setBuildSummary(false)}
      centered
      className="summary-modal"
    >
      <MDBModalHeader className="summary-header">
        <div className="close-icon">
          {isTabView ? (
            <Icon icon="greyX" onClick={() => setBuildSummary(false)} />
          ) : (
            <Icon icon="closeWhite" onClick={() => setBuildSummary(false)} />
          )}
        </div>
        {isTabView ? <h1>Build Summary</h1> : <h1>Summary</h1>}
      </MDBModalHeader>
      <MDBModalBody className="px-4 py-0">
        <div className="summary-wrapper">
          {isTabView ? (
            <div className="tab-view-summary">
              <div className="d-flex justify-content-between">
                <div>
                  <h2 className="your-design">Your Design</h2>
                  <p className="summary-text-below">
                    Below you will find the list of all your selections for your
                    design.
                  </p>
                </div>
                <DownloadTearsheet
                  customer={customer}
                  isCustom={false}
                  customizationOptionsQueryParameters={
                    customizationOptionsQueryParameters
                  }
                  //   uploadConfiguratorImage={uploadConfiguratorImage}
                  id={product?.id}
                  name={product?.name}
                  price={price}
                  icon={downloadIcon}
                  title="Download"
                  //   player={player}
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <img
                    className="product-image-summary"
                    src={product?.image?.url}
                    alt={product?.image?.label || "The product"}
                  />
                </div>
                <div className="col-6">
                  <h2 className="product-name-summary">{product?.name}</h2>
                  {product?.ffi_extdesc2 && (
                    <h4 className="description-summary">
                      {product?.ffi_extdesc2}
                    </h4>
                  )}
                  <p className="summary-detail">
                    Style:{" "}
                    <span className="summary-detail-value">{product?.sku}</span>
                  </p>
                  <p className="summary-detail">
                    Dimensions:{" "}
                    <span className="summary-detail-value">
                      {dimensionsString}
                    </span>
                  </p>
                  {product?.fabric_base_yardage &&
                    product?.fabric_base_yardage !== ".0" &&
                    product?.fabric_base_yardage !== ".00" &&
                    product?.fabric_base_yardage !== "0" && (
                      <h4 className="generic-description">
                        COM:{" "}
                        <span className="generic-description generic-description-value">
                          {product?.fabric_base_yardage + " yards"}
                        </span>
                      </h4>
                    )}
                  {product?.leather_base_square_feet &&
                    product?.leather_base_square_feet !== ".0" &&
                    product?.leather_base_square_feet !== ".00" &&
                    product?.leather_base_square_feet !== "0" && (
                      <h4 className="generic-description">
                        COL:{" "}
                        <span className="generic-description generic-description-value">
                          {product?.leather_base_square_feet + " sq ft"}
                        </span>
                      </h4>
                    )}
                  {product?.description?.html && (
                    <p className="summary-product-description">
                      {product?.description?.html}
                    </p>
                  )}
                </div>
              </div>
              <div className="selected-summary-options">
                {!isMonogram
                  ? optionsUpdated?.map((optionGroup) => {
                      return Object.keys(optionGroup).map((optionKey) => {
                        const filteredOptions = optionGroup[optionKey].filter(
                          (option) =>
                            option?.title !== "Fabric Contrast Welt" &&
                            option?.option_title !== "Fabric Contrast Welt" &&
                            option?.title !== "Leather Contrast Welt" &&
                            option?.option_title !== "Leather Contrast Welt"
                        );

                        return optionKey === "finish_options" &&
                          optionState.finish === undefined
                          ? null
                          : filteredOptions.map((option) => {
                              const selectedOption = findValue(
                                option,
                                optionKey
                              );
                              return selectedOption ? (
                                <div
                                  className="selected-option"
                                  key={option?.option_id}
                                >
                                  <p className="selected-option-title">
                                    {option?.title
                                      ? option?.title
                                      : option?.option_title}
                                  </p>
                                  <div className="selected-option-image-wrap">
                                    {selectedOption?.image ? (
                                      <img
                                        className="selected-option-image"
                                        src={selectedOption?.image?.url}
                                        alt={selectedOption?.name}
                                      />
                                    ) : null}
                                    <p className="selected-option-value">
                                      {showData(selectedOption)}
                                    </p>
                                  </div>

                                  <div></div>
                                </div>
                              ) : null;
                            });
                      });
                    })
                  : null}
              </div>
              <div className="d-flex w-100 justify-content-end">
                <Button
                  className={"summary-exit-button actions-button"}
                  onClick={() => setBuildSummary(false)}
                >
                  Exit
                </Button>
              </div>
            </div>
          ) : (
            <div className={`options ${BYO ? "BYO" : "regular"}`}>
              {BYO ? (
                <div>
                  <h2 className="mb-3">
                    {window.location.pathname.includes("live-edge")
                      ? "Top & Base Selections"
                      : "Build"}
                  </h2>
                  {BYO
                    ? Object.keys(BYO)?.map((option, index) => {
                        return BYO?.[option] ? (
                          BYO?.frame.name === "Ottoman" ? (
                            <div className={`d-flex ${option}`} key={index}>
                              {(BYO?.[option]?.parentName === "Base" ||
                                BYO?.[option]?.parentName === "Size" ||
                                BYO?.[option]?.parentName === "Frame") && (
                                <p style={{ textTransform: "capitalize" }}>
                                  {option === "contrastDrawers"
                                    ? BYO?.[option]?.parentName
                                    : option}
                                </p>
                              )}

                              {(BYO?.[option]?.parentName !== "Size" &&
                                BYO?.[option]?.parentName === "Base") ||
                              BYO?.[option]?.parentName === "Size" ||
                              BYO?.[option]?.parentName === "Frame" ? (
                                <div className={"d-flex"}>
                                  {BYO?.[option]?.image ||
                                  BYO?.[option]?.imageLeft ||
                                  BYO?.[option]?.imageRight ? (
                                    <img
                                      src={
                                        BYO?.[option]?.image
                                          ? BYO?.[option]?.image
                                          : BYO?.[option]?.imageLeft
                                          ? BYO?.[option]?.imageLeft
                                          : BYO?.[option]?.imageRight
                                      }
                                      alt={BYO?.[option]?.name}
                                    />
                                  ) : null}

                                  {BYO?.[option]?.parentName === "Size" ? (
                                    <>
                                      <p className="value">
                                        {" "}
                                        <span className="specs-options-name">
                                          W{" "}
                                        </span>
                                        {size.width}
                                      </p>
                                      <p className="value">
                                        {" "}
                                        <span className="specs-options-name">
                                          D{" "}
                                        </span>{" "}
                                        {size.depth}
                                      </p>
                                      <p className="value">
                                        <span className="specs-options-name">
                                          H{" "}
                                        </span>
                                        {size.height}
                                      </p>
                                    </>
                                  ) : (
                                    <p className="value">
                                      {BYO?.[option]?.relatedsku
                                        ? `${BYO?.[option]?.size} ${BYO?.[option]?.finish}`
                                        : BYO?.[option]?.name}
                                    </p>
                                  )}
                                </div>
                              ) : null}

                              <div></div>
                            </div>
                          ) : (
                            <div className={`d-flex ${option}`} key={index}>
                              <p style={{ textTransform: "capitalize" }}>
                                {option === "contrastDrawers"
                                  ? BYO?.[option]?.parentName
                                  : option}
                              </p>
                              <div className={"d-flex"}>
                                {(BYO?.[option]?.parentName !== "Size" &&
                                  BYO?.[option]?.image) ||
                                BYO?.[option]?.imageLeft ||
                                BYO?.[option]?.imageRight ? (
                                  <img
                                    src={
                                      BYO?.[option]?.image
                                        ? BYO?.[option]?.image
                                        : BYO?.[option]?.imageLeft
                                        ? BYO?.[option]?.imageLeft
                                        : BYO?.[option]?.imageRight
                                    }
                                    alt={BYO?.[option]?.name}
                                  />
                                ) : null}

                                {BYO?.[option]?.parentName === "Size" ? (
                                  <>
                                    <p className="value">
                                      {" "}
                                      <span className="specs-options-name">
                                        W{" "}
                                      </span>
                                      {size.width}
                                    </p>
                                    <p className="value">
                                      {" "}
                                      <span className="specs-options-name">
                                        D{" "}
                                      </span>{" "}
                                      {size.depth}
                                    </p>
                                    <p className="value">
                                      <span className="specs-options-name">
                                        H{" "}
                                      </span>
                                      {size.height}
                                    </p>
                                  </>
                                ) : (
                                  <p className="value">
                                    {BYO?.[option]?.relatedsku
                                      ? `${BYO?.[option]?.size} ${BYO?.[option]?.finish}`
                                      : BYO?.[option]?.name}
                                  </p>
                                )}
                              </div>
                              <div></div>
                            </div>
                          )
                        ) : null;
                      })
                    : null}
                </div>
              ) : null}
              {!isMonogram
                ? optionsUpdated?.map((optionGroup) => {
                    return Object.keys(optionGroup).map((optionKey) => {
                      const filteredOptions = optionGroup[optionKey].filter(
                        (option) =>
                          option?.title !== "Fabric Contrast Welt" &&
                          option?.option_title !== "Fabric Contrast Welt" &&
                          option?.title !== "Leather Contrast Welt" &&
                          option?.option_title !== "Leather Contrast Welt"
                      );

                      return (
                        <div key={optionKey}>
                          {optionKey === "finish_options" &&
                          optionState.finish === undefined
                            ? null
                            : filteredOptions.some((option) =>
                                findValue(option, optionKey)
                              ) && (
                                <h2 className="mb-3">
                                  {optionKey.split("_").join(" ")}
                                </h2>
                              )}
                          {optionKey === "finish_options" &&
                          optionState.finish === undefined
                            ? null
                            : filteredOptions.map((option) => {
                                const selectedOption = findValue(
                                  option,
                                  optionKey
                                );
                                return selectedOption ? (
                                  <div
                                    className="d-flex"
                                    key={option?.option_id}
                                  >
                                    <p>
                                      {option?.title
                                        ? option?.title
                                        : option?.option_title}
                                    </p>
                                    <div className="d-flex">
                                      {selectedOption?.image ? (
                                        <img
                                          src={selectedOption?.image?.url}
                                          alt={selectedOption?.name}
                                        />
                                      ) : null}
                                      <p className="value">
                                        {showData(selectedOption)}
                                      </p>
                                    </div>

                                    <div></div>
                                  </div>
                                ) : null;
                              })}
                        </div>
                      );
                    });
                  })
                : null}
            </div>
          )}
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

export default withRouter(Summary);
