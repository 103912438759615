import React from "react";
import MagicSliderDots from "react-magic-slider-dots";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { MDBBox, MDBCol, MDBRow, MDBTypography, MDBContainer } from "mdbreact";
import Icon from "../../../../../assets/icon/icon";
import Price from "core/components/price";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import { useSelector } from "react-redux";
export const getBadges = (product) =>
  product
    ? Object.entries({
        senior: product?.ffi_lanesl,
        retail: product?.ffi_laners,
        contract: product?.ffi_lanect,
      })
        .filter(([, value]) => !!value)
        .map(([key], i, a) => (
          <div key={key} className={i !== a.length - 1 ? "mr-5" : ""}>
            <Icon icon={key} />
          </div>
        ))
    : null;

const RelatedProducts = ({ relatedProducts }) => {
  const sliderProps = {
    className: "related-slider",
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    controls: false,
    arrows: true,
    dots: true,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={30} />;
    },
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          controls: false,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 3000,
          dots: false,
        },
      },
    ],
  };
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <>
      {relatedProducts && relatedProducts.length > 0 && (
        <MDBContainer className="related-container-wrap">
          <div className={"related-container"}>
            <MDBRow className="w-100 m-0">
              <MDBCol className="d-none d-lg-block px-0">
                <MDBBox className="h-50 border-bottom border-default w-100" />
              </MDBCol>
              <MDBCol className="d-flex justify-content-center p-0 align-items-center">
                <MDBTypography
                  className="related-title font-weight-light"
                  tag="h2"
                  variant="display-2"
                >
                  Related Products
                </MDBTypography>
              </MDBCol>
              <MDBCol className="d-none d-lg-block px-0">
                <MDBBox className="h-50 border-bottom border-default w-100" />
              </MDBCol>
            </MDBRow>

            <Slider {...sliderProps} className="related-products-slider">
              {relatedProducts.map((product, index) => {
                const isCommercialGrade = product?.product_features_label?.includes(
                  "Commercial-Grade"
                );
                let price =
                  product?.price_range?.minimum_price?.final_price?.value ||
                  undefined;
                return (
                  <Link to={`/${product.url_key}`} key={index}>
                    <div className={"product-container"}>
                      <img src={product.image.url} alt={product.image.label} />
                      <p>
                        <MDBTypography
                          tag={"h4"}
                          className={`w-100 text-center product-title ${"finish-fix-text"}`}
                        >
                          {product?.ffi_name || product?.name}
                        </MDBTypography>

                        {product?.ffi_extdesc2 && (
                          <MDBRow className="pt-2 pb-2">
                            <MDBTypography
                              tag="h5"
                              className={`w-100 text-center`}
                            >
                              {product?.ffi_extdesc2}
                            </MDBTypography>
                          </MDBRow>
                        )}
                        {product?.ffifabrics_grade?.[0] !== "false" && (
                          <MDBRow className={`pt-2 pb-2  `}>
                            <MDBTypography
                              tag="h5"
                              className={`w-100 text-center commercial-grade`}
                            >
                              {product?.ffifabrics_grade}
                            </MDBTypography>
                          </MDBRow>
                        )}
                        <MDBRow className={`pt-2 pb-2 product-name`}>
                          <MDBTypography
                            tag="h5"
                            className="w-100 text-center product-sku"
                          >
                            {product?.sku}
                          </MDBTypography>
                        </MDBRow>

                        {product?.findFabric && (
                          <MDBRow className={`pt-3 pb-2 product-name`}>
                            <MDBTypography
                              tag="h5"
                              className="w-100 text-center"
                            >
                              Main Fabric: {product?.findFabric}
                            </MDBTypography>
                          </MDBRow>
                        )}

                        {isCommercialGrade && (
                          <MDBRow>
                            <MDBTypography
                              tag="h5"
                              className="w-100 text-center fwmedium"
                            >
                              Commercial-Grade
                            </MDBTypography>
                          </MDBRow>
                        )}
                        {price && isLoggedIn && (
                          <MDBRow className="pt-3 pb-lg-4 pb-0">
                            <MDBTypography
                              tag="h5"
                              className="body-1 w-100 fwmedium text-center fs-14 product-price"
                            >
                              From: <Price price={price} type={"regular"} />
                            </MDBTypography>
                          </MDBRow>
                        )}
                        {/* <MDBRow className="">
                          <MDBTypography
                            tag={"h4"}
                            className={`w-100 text-center`}
                          >
                            {product?.name}
                          </MDBTypography>
                        </MDBRow>

                        <MDBRow className="">
                          <MDBTypography
                            tag={"h5"}
                            className={`w-100 text-center font-weight-bold`}
                          >
                            {product?.ffi_extdesc2 || ''}
                          </MDBTypography>
                        </MDBRow>

                        <MDBRow className="">
                          <MDBTypography
                            tag={"h5"}
                            className={`w-100 text-center`}
                          >
                            {product.sku}
                          </MDBTypography>
                        </MDBRow> */}
                      </p>
                      {/* <div className={"badges"}>
                        <div className={"badge-container"}>
                          {getBadges(product)}
                        </div>
                      </div> */}
                    </div>
                  </Link>
                );
              })}
            </Slider>
          </div>
        </MDBContainer>
      )}
    </>
  );
};

export default RelatedProducts;
