import { MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import CmsBlock from "app/layout/cmsBlock";

const ToTheTrade = () => {
  return (
    <MDBContainer className="m-0 hero-banner" fluid>
      <CmsBlock id={"to-the-trade-hero"} />
      <MDBRow className="to-the-trade-content flex justify-content-center">
        <CmsBlock id={"page-to-the-trade"} />
      </MDBRow>
      <CmsBlock id={"airtable-form"} />
    </MDBContainer>
  );
};

export default ToTheTrade;
