import React from "react";
import { MDBCol, MDBContainer } from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";

export const Promos = () => {
  return (
    <>
      <MDBContainer className="promo-container py-5">
        <MDBCol>
          <CmsBlock id="pdp-promo-1" />
          <CmsBlock id="pdp-promo-2" />
        </MDBCol>
      </MDBContainer>
      <MDBContainer fluid className="bg-gray-lighten-4 p-4">
        <CmsBlock id="installation_gallery" />
      </MDBContainer>
    </>
  );
};
