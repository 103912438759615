import React, { useEffect, useState } from "react";
import Layout from "./layout";
import apolloClient from "core/graphql/apolloClient";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { Provider as ReduxProvider } from "react-redux";
import { Router } from "react-router-dom";
import Cache from "app/layout/cache";
import store from "core/state/redux/store";
import history from "core/state/redux/history";
import "./main.scss";
import ScrollToTop from "react-router-scroll-top";
import { CheckBoxContext } from "./pages/catalog/product/checkboxContext";
import themeConfiguration from "../config/themeConfiguration";
import ReactGa from "react-ga4";
import "lazysizes";
import useDeepCompareEffect from "use-deep-compare-effect";

const App = () => {
  const [checkBoxOptions, setCheckBoxOptions] = useState({});
  const [viewMoreOptions, setViewMoreOptions] = useState([{}]);
  const [selectedFinish, setSelectedFinish] = useState({});
  const [selectedContrastWelt, setSelectedContrastWelt] = useState();
  const [selectedDecorativeCord, setSelectedDecorativeCord] = useState();
  const [selectedFringe, setSelectedFringe] = useState();
  const [preselection, setPreselection] = useState({});
  const [
    selectedThrowPillowContrastWelt,
    setSelectedThrowPillowContrastWelt,
  ] = useState();
  const [
    selectedThrowPillowDecorativeCord,
    setSelectedThrowPillowDecorativeCord,
  ] = useState();
  const [selectedThrowPillowFringe, setSelectedThrowPillowFringe] = useState();
  const [
    selectedKidneyPillowContrastWelt,
    setSelectedKidneyPillowContrastWelt,
  ] = useState();
  const [
    selectedKidneyPillowDecorativeCord,
    setSelectedKidneyPillowDecorativeCord,
  ] = useState();
  const [
    selectedKidneyPillowFringe,
    setSelectedKidneyPillowFringe,
  ] = useState();
  const [selectedContrastButtons, setSelectedContrastButtons] = useState();
  const [nailPrice, setNailPrice] = useState(0);

  useEffect(() => {
    if (
      window.location.host === "fairfieldchair.com" ||
      window.location.host === "www.fairfieldchair.com"
    ) {
      ReactGa.initialize("G-6T3FW921L3");
      // ReactGa.send(window.location.pathname + window.location.search);
      ReactGa.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });

      history.listen((location) => {
        if (location.pathname) {
          ReactGa.send(location.pathname + location.search);
        }
      });
    }
    if (window?.dataLayer) {
      window.dataLayer.push({
        event: "page_view",
        page_title: window.location.pathname,
        page_location: window.location.href,
      });
    }
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <ApolloHooksProvider client={apolloClient}>
        <ReduxProvider store={store}>
          <Cache>
            <Router history={history}>
              <ScrollToTop>
                <CheckBoxContext.Provider
                  value={{
                    checkBoxOptions,
                    setCheckBoxOptions,
                    viewMoreOptions,
                    setViewMoreOptions,
                    selectedFinish,
                    setSelectedFinish,
                    selectedContrastWelt,
                    setSelectedContrastWelt,
                    selectedContrastButtons,
                    setSelectedContrastButtons,
                    selectedDecorativeCord,
                    setSelectedDecorativeCord,
                    selectedFringe,
                    setSelectedFringe,
                    selectedThrowPillowContrastWelt,
                    setSelectedThrowPillowContrastWelt,
                    selectedThrowPillowDecorativeCord,
                    setSelectedThrowPillowDecorativeCord,
                    selectedThrowPillowFringe,
                    setSelectedThrowPillowFringe,
                    selectedKidneyPillowContrastWelt,
                    setSelectedKidneyPillowContrastWelt,
                    selectedKidneyPillowDecorativeCord,
                    setSelectedKidneyPillowDecorativeCord,
                    selectedKidneyPillowFringe,
                    setSelectedKidneyPillowFringe,
                    preselection,
                    setPreselection,
                    nailPrice,
                    setNailPrice,
                  }}
                >
                  <Layout />
                </CheckBoxContext.Provider>
              </ScrollToTop>
            </Router>
          </Cache>
        </ReduxProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  );
};

export default App;
