import React from "react";
import HeroBanner from "./HeroBanner";
import Shop from "./Shop";
import CmsBlock from "app/layout/cmsBlock";

const HomeContainerV2 = () => {
  return (
    <>
      <HeroBanner />

      {/* <CmsBlock id="crypton_hero_banner" /> */}
      <Shop />
      <CmsBlock id="homepage_v2_multi-block" />
      <CmsBlock id="read_all_about_it" />
      <CmsBlock id="residential_to_the_trade" />
      <CmsBlock id="residential_lets_get_social" />
      <CmsBlock id="commercial_2024_lookbook" />
      <CmsBlock id="commercial_lets_get_social" />
      <CmsBlock id="homepage_v2_i-am-fairfield" />
    </>
  );
};

export default HomeContainerV2;
