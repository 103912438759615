import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import CustomPriceContext from "../customPriceContext";
import Options from "../components/options";
import Specs from "../components/specs";
import Toolbar from "../components/toolbar";
import Steps from "./components/steps";
import { Player } from "../../../layout/Player";
import { nails as benchMadeRONails } from "app/pages/Custom/benchmade-round-ottomans/data";
import Breadcrumbs from "app/layout/breadcrumbs";
import { floors as benchmadeROFloorArray } from "../../Custom/benchmade-round-ottomans/data";
import {
  floors as benchmadeRSFloorArray,
  sizes,
} from "../../Custom/benchmade-square-ottomans/data";
import { floors as benchMadeBenchesFloors } from "../../Custom/benchmade-benches/data";
import finishes from "generation/finishes";
import {
  floorArray as urbanFloorArray,
  fabricArray as urbanFabricArray,
  baseArray as urbanBaseArray,
} from "../../Custom/urban/data";
import {
  floorArray as seniorFloorArray,
  fabricArray as seniorFabricArray,
  baseArray as seniorBaseArray,
} from "../../Custom/senior/data";
import { bases as baseBBArray } from "../../Custom/benchmade-benches/data";
import { bases as baseBROArray } from "../../Custom/benchmade-round-ottomans/data";
import { bases as baseBSOArray } from "../../Custom/benchmade-square-ottomans/data";
import { floorArray as monogramFloorArray } from "../../Custom/monogram/data";
import {
  floorArray as havenFloorArray,
  baseArray as heavenBaseArray,
  fabricArray,
  baseFinishArray,
  nailFinishArray,
  frameNailSizeArray,
} from "../../Custom/haven/data";
import { PromosBYO } from "../../catalog/product/PromosBYO";
// import { useQuery } from "react-apollo-hooks";
// import { CUSTOMER_PRODUCT, PRODUCT } from "../../catalog/product/queries";
// import useCustomer from "app/state/hooks/customer/useCustomer";
import IconContainer from "../components/iconContainer";
import Gallery from "../genericPDP/components/gallery";
import { useMediaQuery } from "react-responsive";
import qs from "qs";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import { useSelector } from "react-redux";
import useLiveEdgeHook from "./liveEdge/useLiveEdgeHook";
import { pathnameIncludes } from "app/utils/utils";
import useTagboxHelper from "app/layout/Tagbox/useTagboxHelper";

const CustomPDP = ({
  product,
  optionState,
  optionDispatch,
  availableIn,
  mappedSpecs,
  downloadTearSheet,
  calculateOptions,
  type,
  sku,
  setSku,
  initialProduct,
  optionsSelected,
  setOptionsSelected,
  isEdit,
  finishError,
  setFinishError,
}) => {
  const { tag } = useTagboxHelper();
  const { dispatch: priceDispatch } = useContext(CustomPriceContext);
  const canvasRef = useRef(null);
  const [checkBoxOptionsCustom, setCheckBoxOptionsCustom] = useState({
    nailhead_trim_option: !!optionState.nail_size,
  });

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });

  const [toggleFrameOptions, setToggleFrameOptions] = useState(true);

  useEffect(() => {
    priceDispatch({
      type: "setMinPrice",
      minPrice: product?.price_range?.minimum_price?.final_price?.value || 0,
    });
  }, [priceDispatch, product?.price_range?.minimum_price?.final_price?.value]);
  const [frame, setFrame] = useState(initialProduct.frame || null);
  const [size, setSize] = useState(initialProduct.size || null);
  const [back, setBack] = useState(initialProduct.back || null);
  const [arm, setArm] = useState(initialProduct.arm || null);
  const [base, setBase] = useState(initialProduct.base || null);
  const [seat, setSeat] = useState(initialProduct.seat || null);
  const [model, setModel] = useState(initialProduct.model || null);
  const [striping, setStriping] = useState(initialProduct.striping || null);
  const [finish, setFinish] = useState(initialProduct.finish || null);
  const {
    liveEdgeDimensions,
    liveEdgePrice,
    numOfBases,
    setNumOfBases,
  } = useLiveEdgeHook({
    isLoggedIn,
    sku,
    base,
  });

  // useEffect(
  //   () => window.history.pushState(null, "", window.location.pathname),
  //   [frame]
  // );

  const [top, setTop] = useState(initialProduct.top || null);
  const [baseFinish, setBaseFinish] = useState(
    initialProduct.baseFinish || null
  );

  const [fabric, setFabric] = useState(initialProduct.fabric || null);
  const [nail, setNail] = useState(initialProduct.nail || null);
  const [contrastDrawers, setContrastDrawers] = useState(
    initialProduct.finish || null
  );

  const [floor, setFloor] = useState(
    type === "Haven"
      ? havenFloorArray.find((floor) => floor.default)
      : type === "Urban"
      ? urbanFloorArray.find((floor) => floor.default)
      : type === "Senior"
      ? seniorFloorArray.find((floor) => floor.default)
      : type === "Monogram"
      ? monogramFloorArray.find((floor) => floor.default)
      : type === "BenchmadeRoundOttomans"
      ? benchmadeROFloorArray.find((floor) => floor.default)
      : type === "BenchmadeSquareOttomans"
      ? benchmadeRSFloorArray.find((floor) => floor.default)
      : type === "BenchmadeBenches"
      ? benchMadeBenchesFloors.find((floor) => floor.default)
      : null
  );
  const [seatAndBack, setSeatAndBack] = useState(
    initialProduct.seatAndBack || null
  );
  const [accessoryLeft, setAccessoryLeft] = useState(
    initialProduct.accessoryLeft || null
  );

  const [accessoryRight, setAccessoryRight] = useState(
    initialProduct.accessoryRight || null
  );
  const [powerOption, setPowerOption] = useState(
    initialProduct.powerOption || null
  );
  const [tearSheet, setTearSheet] = useState(downloadTearSheet);

  useEffect(() => {
    if (window.location.pathname.includes("build-your-own/live-edge"))
      setSku(`${top?.sku}`);
  }, [top, base]);

  // useEffect(() => {
  //   if (window.location.pathname.includes("monogram") && optionState?.finish) {
  //     const monogram = finishes.find((finish) =>
  //       finish.name.toLowerCase().includes("monogram")
  //     );

  //     let finishFound = monogram.products.items.find(
  //       (finish) => finish.sku === optionState?.finish
  //     );

  //     let finishSelected = monogramFinishArray.find(
  //       (finish) =>
  //         finishFound?.name.split("-")?.[0]?.includes(finish?.name) ||
  //         finish.name.includes(finishFound?.name.split("-")?.[0])
  //     );
  //     if (finishSelected) setFinish(finishSelected);
  //   }
  // }, [optionState?.finish]);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [player, setPlayer] = useState(null);
  const [initLoad, setInitLoad] = useState(false);
  const [modelReady, setModelReady] = useState(true);

  useEffect(() => {
    if (optionState?.baseName) {
      const baseName = optionState?.baseName;
      const pathname = window.location.pathname;
      let baseArray = pathname?.includes("benchmade-benches")
        ? baseBBArray
        : pathname?.includes("haven-reserve")
        ? heavenBaseArray
        : pathname?.includes("round-ottomans")
        ? baseBROArray
        : pathname?.includes("square-ottomans")
        ? baseBSOArray
        : pathname?.includes("senior")
        ? seniorBaseArray
        : urbanBaseArray;
      setBase(baseArray.find((base) => base.name === baseName));
    }
  }, []);

  const toggleFullscreen = () => setIsFullscreen(!isFullscreen);
  const isLiveEdge = window.location.pathname.includes("live-edge");

  useEffect(() => {
    if (accessoryLeft)
      optionDispatch({
        type: "setAttribute",
        attribute: "accessoryLeft",
        payload: accessoryLeft?.id,
      });
  }, [accessoryLeft]);

  useEffect(() => {
    if (accessoryRight)
      optionDispatch({
        type: "setAttribute",
        attribute: "accessoryRight",
        payload: accessoryRight?.id,
      });
  }, [accessoryRight]);

  const productDimensions = {
    width: product.width,
    height: product.height,
    depth: product.depth,
  };
  function isPelleCollection() {
    let isPelle = false;
    if (product?.collection) {
      product.collection.forEach((col) => {
        if (col.toLowerCase() === "pelle provisions") {
          isPelle = true;
        }
      });
    }
    return isPelle;
  }
  const selectedFabricsAndFinishes = useMemo(() => {
    const nailSizeOption = product?.options?.find(
      (option) => option.title === "Nail Size"
    );

    const localFabricArray =
      type === "Senior"
        ? seniorFabricArray
        : type === "Urban"
        ? urbanFabricArray
        : fabricArray;

    const removeFabricWordFromSku = (sku) =>
      sku?.includes("fabric") ? sku?.replace("fabric-", "") : sku;

    return {
      mainFabric: localFabricArray.find((fabric) =>
        typeof optionState?.main_fabric === "string"
          ? fabric.id === removeFabricWordFromSku(optionState.main_fabric)
          : fabric.name === "Linen"
      ),
      backFabric: localFabricArray.find((fabric) =>
        optionState?.outside_back
          ? fabric.id === removeFabricWordFromSku(optionState.outside_back)
          : fabric.name === "Linen"
      ),
      insideBack: localFabricArray.find((fabric) =>
        optionState?.back_pillow
          ? fabric.id === removeFabricWordFromSku(optionState.back_pillow)
          : fabric.name === "Linen"
      ),
      weltFabric: localFabricArray.find((fabric) =>
        optionState?.contrasting_welt
          ? fabric.id === removeFabricWordFromSku(optionState.contrasting_welt)
          : fabric.name === "Linen"
      ),
      pillowWeltFabric: localFabricArray.find((fabric) =>
        optionState?.throw_pillow_contrast_welt
          ? fabric.id ===
            removeFabricWordFromSku(optionState.throw_pillow_contrast_welt)
          : fabric.name === "Linen"
      ),
      cushionFabric: localFabricArray.find((fabric) =>
        optionState?.cushion
          ? fabric.id === removeFabricWordFromSku(optionState.cushion)
          : fabric.name === "Linen"
      ),
      throwPillowFabric: localFabricArray.find((fabric) =>
        optionState?.throw_pillow_contrast
          ? fabric.id ===
            removeFabricWordFromSku(optionState.throw_pillow_contrast)
          : fabric.name === "Linen"
      ),
      nailSize: frameNailSizeArray.find(
        (nailSize) =>
          nailSize.name ===
          nailSizeOption?.value.find(
            (value) => value.option_type_id === optionState.nail_size
          )?.title
      ),
      nailFinish: optionState.nail_finish
        ? {
            finish: nailFinishArray.find(
              (finish) => finish.name === optionState.nail_finish
            ),
          }
        : undefined,
      selectedBaseFinish: baseFinishArray.find(
        (finish) => finish.id === optionState.finish
      ),
    };
  }, [optionState]);

  useEffect(() => {
    if (!optionState.nail_size && checkBoxOptionsCustom.nailhead_trim_option) {
      setCheckBoxOptionsCustom({
        nailhead_trim_option: false,
      });
    }
  }, [checkBoxOptionsCustom.nailhead_trim_option, optionState.nail_size]);

  useEffect(() => {
    if (checkBoxOptionsCustom.nailhead_trim_option && !optionState.nail_size) {
      product.options &&
        product.options
          .filter(
            (option) =>
              option.title.toLowerCase().split(" ").join("_") === "nail_size"
          )
          .forEach((option) => {
            if (!optionState.nail_size) {
              optionDispatch({
                type: "setAttribute",
                attribute: option.title.toLowerCase().split(" ").join("_"),
                payload: option.value[0].option_type_id,
              });
            }
          });
    } else if (
      !checkBoxOptionsCustom.nailhead_trim_option &&
      optionState.nail_size
    ) {
      optionDispatch({
        type: "setAttribute",
        attribute: "nail_size",
        payload: false,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "nail_finish",
        payload: false,
      });
    }
  }, [checkBoxOptionsCustom.nailhead_trim_option]);

  useEffect(() => {
    if (type === "Haven" && frame && arm && back && base) {
      setSku("1" + arm.id + back.id + base.id + "-" + frame.sku);
    }

    if (type === "Urban" && seat && arm && back && base && frame) {
      setSku(seat?.id + arm.id + back.id + base.id + "-" + frame.sku);
    }

    if (
      type === "BenchmadeRoundOttomans" &&
      frame &&
      base &&
      nail &&
      top &&
      size
    )
      setSku(`${frame.id}${top.id}${nail.id}${base.id}-${size?.id || size}`);
    if (
      type === "BenchmadeSquareOttomans" &&
      frame &&
      base &&
      nail &&
      top &&
      size
    ) {
      setSku(`${frame.id}${top.id}${nail.id}${base.id}-${size?.id}`);
    }
    if (type === "BenchmadeBenches" && frame && base)
      setSku(`${frame.id?.replace("-", "")}${base.id}-10`);

    if (
      type === "Senior" &&
      frame &&
      powerOption &&
      arm &&
      accessoryLeft &&
      accessoryRight &&
      base &&
      seatAndBack
    ) {
      let accessory =
        (accessoryRight !== "X" && accessoryLeft !== "X" && "B") ||
        (accessoryLeft !== "X" && "L") ||
        (accessoryRight !== "X" && "R") ||
        "X";

      setSku(
        frame.recliner
          ? "S-" +
              (powerOption?.id === "SR"
                ? frame.uvc
                  ? 449
                  : arm.reclinerId + 5
                : arm.reclinerId) +
              frame.sku +
              "-" +
              powerOption?.id
          : base?.id === "R" || base?.id === "Q"
          ? seatAndBack?.id +
            arm?.id +
            base?.id +
            accessory +
            "-" +
            frame.sku +
            "-" +
            base.finishId
          : seatAndBack?.id + arm?.id + base?.id + accessory + "-" + frame.sku
      );
    }

    if (type === "Monogram" && model) {
      setSku(model.sku);
    }

    if (contrastDrawers) contrastDrawers.parentName = "Contrast Drawer Finish";

    const searchObj = qs.parse(window.location.search);
    const monogramFinishes = finishes.find(
      (finish) => finish.name === "Monogram Finishes"
    );

    const build_your_own_options = {
      frame,
      back,
      arm,
      base,
      seat,
      seatAndBack,
      accessoryLeft,
      accessoryRight,
      powerOption,
      model,
      top,
      nail,
      size,
      contrastDrawers,
      striping,
      finish,
    };
    if (optionsSelected) {
      optionsSelected.forEach((option) => delete option["byo_options"]);
      optionsSelected = optionsSelected.filter(
        (option) => Object.keys(option).length > 0
      );
      setOptionsSelected(() => [
        ...optionsSelected,
        { byo_options: build_your_own_options },
      ]);
    }

    if (
      !window.location.pathname.includes("benchmade-round-ottomans") &&
      !window.location.pathname.includes("benchmade-ottomans")
    ) {
      delete build_your_own_options.nail;
    }
    if (window.location.pathname.includes("monogram")) {
      if (
        !build_your_own_options.contrastDrawers &&
        searchObj["contrastDrawers"]
      ) {
        build_your_own_options.contrastDrawers = monogramFinishes?.products?.items?.find(
          (item) => item.id === +searchObj["contrastDrawers"]
        );
      }

      if (!build_your_own_options.finish && searchObj["finish"]) {
        build_your_own_options.finish = monogramFinishes?.products?.items?.find(
          (item) => item.id === +searchObj["finish"]
        );
      }
    }

    let return_build_your_own_options = {};

    let downloadUrl = downloadTearSheet;
    Object.keys(build_your_own_options).forEach((key) => {
      if (build_your_own_options[key]) {
        return_build_your_own_options[key] = build_your_own_options[key];
        // Only send powerOption value if frame has recliner true
        // Only send base value if its not recliner true
        // Only send seatAndBack value if its not recliner true and frame name isn't Ottoman
        // Only send arm value if its not Ottoman frame name
        if (
          (key !== "powerOption" &&
            key !== "base" &&
            key !== "seatAndBack" &&
            key !== "arm") ||
          (build_your_own_options["frame"]?.recliner &&
            key === "powerOption") ||
          (!build_your_own_options["frame"]?.recliner &&
            (key === "base" ||
              (key === "seatAndBack" &&
                build_your_own_options["frame"].name !== "Ottoman"))) ||
          (key === "arm" && build_your_own_options["frame"].name !== "Ottoman")
        ) {
          let capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
          downloadUrl =
            downloadUrl +
            "&build_overview_" +
            capitalizedKey +
            "=" +
            build_your_own_options[key]?.name;
        }
      }
    });
    setTearSheet(downloadUrl);

    optionDispatch({
      type: "setAttribute",
      attribute: "build_your_own_options",
      payload: JSON.stringify(return_build_your_own_options),
    });
  }, [
    frame,
    back,
    arm,
    base,
    seat,
    seatAndBack,
    accessoryLeft,
    accessoryRight,
    powerOption,
    model,
    nail,
    top,
    size,
    contrastDrawers,
    striping,
    finish,
    downloadTearSheet,
  ]);

  useEffect(() => {
    if (
      (window.location.pathname.includes("round-ottomans") ||
        window.location.pathname.includes("square-ottomans")) &&
      !optionState?.nail_size
    ) {
      setNail(benchMadeRONails.find((nail) => nail.id === "S"));
    }
  }, [optionState?.nail_size]);

  useEffect(() => {
    if (
      base?.id === "F" &&
      (nail?.id === "D" || nail?.id === "X") &&
      window.location.pathname.includes("round-ottomans")
    )
      setNail(benchMadeRONails.find((nail) => nail.id === "S"));
    // else setNail(benchMadeRONails.find((nail) => nail.id === "X"));
  }, [base]);

  const loadPlayer = (type) => {
    switch (type) {
      case "Haven":
        return (
          <Player
            ref={canvasRef}
            isFullscreen={isFullscreen}
            toggleFullscreen={toggleFullscreen}
            player={player}
            setPlayer={setPlayer}
            frame={frame}
            arm={arm}
            back={back}
            base={base}
            floor={floor}
            floorOptions={havenFloorArray}
            setFloor={setFloor}
            mainFabric={selectedFabricsAndFinishes.mainFabric}
            insideBack={selectedFabricsAndFinishes.insideBack}
            backFabric={selectedFabricsAndFinishes.backFabric}
            weltFabric={selectedFabricsAndFinishes.weltFabric}
            pillowWeltFabric={selectedFabricsAndFinishes.pillowWeltFabric}
            cushionFabric={selectedFabricsAndFinishes.cushionFabric}
            throwPillowFabric={selectedFabricsAndFinishes.throwPillowFabric}
            nailSize={selectedFabricsAndFinishes.nailSize}
            nailFinish={selectedFabricsAndFinishes.nailFinish}
            sku={sku}
            selectedBaseFinish={selectedFabricsAndFinishes.selectedBaseFinish}
            checkBoxOptionsCustom={checkBoxOptionsCustom}
            setCheckBoxOptionsCustom={setCheckBoxOptionsCustom}
            collectionPath={"haven-reserve"}
            productDimensions={productDimensions}
            initialProduct={initialProduct}
            modelReady={true}
          />
        );

      case "Urban":
        return (
          <Player
            ref={canvasRef}
            isFullscreen={isFullscreen}
            toggleFullscreen={toggleFullscreen}
            player={player}
            setPlayer={setPlayer}
            seat={seat}
            insideBack={selectedFabricsAndFinishes.insideBack}
            frame={frame}
            arm={arm}
            selectedBaseFinish={selectedFabricsAndFinishes.selectedBaseFinish}
            back={back}
            base={base}
            floor={floor}
            floorOptions={urbanFloorArray}
            setFloor={setFloor}
            mainFabric={selectedFabricsAndFinishes.mainFabric}
            backFabric={selectedFabricsAndFinishes.backFabric}
            weltFabric={selectedFabricsAndFinishes.weltFabric}
            pillowWeltFabric={selectedFabricsAndFinishes.decorativeCordFabric}
            cushionFabric={selectedFabricsAndFinishes.cushionFabric}
            throwPillowFabric={selectedFabricsAndFinishes.throwPillowFabric}
            nailSize={selectedFabricsAndFinishes.nailSize}
            nailFinish={selectedFabricsAndFinishes.nailFinish}
            sku={sku}
            collectionPath={"urban-living"}
            checkBoxOptionsCustom={checkBoxOptionsCustom}
            setCheckBoxOptionsCustom={setCheckBoxOptionsCustom}
            productDimensions={productDimensions}
            initialProduct={initialProduct}
            modelReady={modelReady}
          />
        );

      case "Senior":
        return (
          <Player
            ref={canvasRef}
            isFullscreen={isFullscreen}
            toggleFullscreen={toggleFullscreen}
            player={player}
            setPlayer={setPlayer}
            frame={frame}
            arm={arm}
            seatAndBack={seatAndBack}
            insideBack={selectedFabricsAndFinishes.insideBack}
            base={base}
            floor={floor}
            floorOptions={seniorFloorArray}
            setFloor={setFloor}
            mainFabric={selectedFabricsAndFinishes.mainFabric}
            backFabric={selectedFabricsAndFinishes.backFabric}
            weltFabric={selectedFabricsAndFinishes.weltFabric}
            pillowWeltFabric={selectedFabricsAndFinishes.decorativeCordFabric}
            cushionFabric={selectedFabricsAndFinishes.cushionFabric}
            throwPillowFabric={selectedFabricsAndFinishes.throwPillowFabric}
            nailSize={selectedFabricsAndFinishes.nailSize}
            nailFinish={selectedFabricsAndFinishes.nailFinish}
            sku={sku}
            collectionPath={"senior-living-solutions"}
            checkBoxOptionsCustom={checkBoxOptionsCustom}
            setCheckBoxOptionsCustom={setCheckBoxOptionsCustom}
            accessoryLeft={accessoryLeft}
            accessoryRight={accessoryRight}
            powerOption={powerOption}
            productDimensions={productDimensions}
            initialProduct={initialProduct}
            modelReady={true}
          />
        );

      case "Monogram":
        return (
          <Player
            ref={canvasRef}
            isFullscreen={isFullscreen}
            toggleFullscreen={toggleFullscreen}
            player={player}
            setPlayer={setPlayer}
            frame={model}
            striping={striping}
            finish={finish}
            floor={floor}
            floorOptions={monogramFloorArray}
            setFloor={setFloor}
            contrastDrawers={contrastDrawers}
            sku={sku}
            collectionPath={"monogram"}
            checkBoxOptionsCustom={checkBoxOptionsCustom}
            setCheckBoxOptionsCustom={setCheckBoxOptionsCustom}
            productDimensions={productDimensions}
            initialProduct={initialProduct}
            modelReady={true}
          />
        );
      case "LiveEdge":
        return (
          <Player
            ref={canvasRef}
            isFullscreen={isFullscreen}
            toggleFullscreen={toggleFullscreen}
            player={player}
            setNumOfBases={setNumOfBases}
            setPlayer={setPlayer}
            sku={sku}
            collectionPath={"live-edge"}
            top={top}
            base={base}
            setTop={setTop}
            setBase={setBase}
            checkBoxOptionsCustom={checkBoxOptionsCustom}
            setCheckBoxOptionsCustom={setCheckBoxOptionsCustom}
            productDimensions={liveEdgeDimensions}
            initialProduct={initialProduct}
            modelReady={true}
          />
        );
      case "BenchmadeRoundOttomans":
        return (
          <Player
            ref={canvasRef}
            isFullscreen={isFullscreen}
            toggleFullscreen={toggleFullscreen}
            player={player}
            setPlayer={setPlayer}
            style={frame}
            finish={finish}
            floor={floor}
            floorOptions={benchmadeROFloorArray}
            top={top}
            nail={nail}
            fabric={fabric}
            baseFinish={baseFinish}
            setFloor={setFloor}
            sku={sku}
            base={base}
            size={sizes.find(
              (item) => item?.sku === size || item?.sku === size?.id
            )}
            mainFabric={selectedFabricsAndFinishes.mainFabric}
            backFabric={selectedFabricsAndFinishes.backFabric}
            weltFabric={selectedFabricsAndFinishes.weltFabric}
            pillowWeltFabric={selectedFabricsAndFinishes.decorativeCordFabric}
            cushionFabric={selectedFabricsAndFinishes.cushionFabric}
            throwPillowFabric={selectedFabricsAndFinishes.throwPillowFabric}
            nailSize={selectedFabricsAndFinishes.nailSize}
            nailFinish={selectedFabricsAndFinishes.nailFinish}
            collectionPath={"benchmade-round-ottomans"}
            selectedBaseFinish={selectedFabricsAndFinishes.selectedBaseFinish}
            checkBoxOptionsCustom={checkBoxOptionsCustom}
            setCheckBoxOptionsCustom={setCheckBoxOptionsCustom}
            accessoryLeft={accessoryLeft}
            accessoryRight={accessoryRight}
            powerOption={powerOption}
            productDimensions={productDimensions}
            initialProduct={initialProduct}
          />
        );
      case "BenchmadeSquareOttomans":
        return (
          <Player
            ref={canvasRef}
            isFullscreen={isFullscreen}
            toggleFullscreen={toggleFullscreen}
            player={player}
            setPlayer={setPlayer}
            style={frame}
            finish={finish}
            floor={floor}
            floorOptions={benchmadeROFloorArray}
            top={top}
            nail={nail}
            fabric={fabric}
            baseFinish={baseFinish}
            setFloor={setFloor}
            sku={sku}
            base={base}
            size={sizes.find((item) => item?.sku === size?.id)}
            mainFabric={selectedFabricsAndFinishes.mainFabric}
            backFabric={selectedFabricsAndFinishes.backFabric}
            weltFabric={selectedFabricsAndFinishes.weltFabric}
            pillowWeltFabric={selectedFabricsAndFinishes.decorativeCordFabric}
            cushionFabric={selectedFabricsAndFinishes.cushionFabric}
            throwPillowFabric={selectedFabricsAndFinishes.throwPillowFabric}
            selectedBaseFinish={selectedFabricsAndFinishes.selectedBaseFinish}
            nailSize={selectedFabricsAndFinishes.nailSize}
            nailFinish={selectedFabricsAndFinishes.nailFinish}
            collectionPath={"benchmade-ottomans"}
            checkBoxOptionsCustom={checkBoxOptionsCustom}
            setCheckBoxOptionsCustom={setCheckBoxOptionsCustom}
            accessoryLeft={accessoryLeft}
            accessoryRight={accessoryRight}
            powerOption={powerOption}
            productDimensions={productDimensions}
            initialProduct={initialProduct}
          />
        );

      case "BenchmadeBenches":
        return (
          <Player
            ref={canvasRef}
            isFullscreen={isFullscreen}
            toggleFullscreen={toggleFullscreen}
            player={player}
            setPlayer={setPlayer}
            style={frame}
            finish={finish}
            floor={floor}
            floorOptions={benchmadeROFloorArray}
            fabric={fabric}
            baseFinish={baseFinish}
            setFloor={setFloor}
            sku={sku}
            base={base}
            size={size}
            mainFabric={selectedFabricsAndFinishes.mainFabric}
            backFabric={selectedFabricsAndFinishes.backFabric}
            weltFabric={selectedFabricsAndFinishes.weltFabric}
            pillowWeltFabric={selectedFabricsAndFinishes.decorativeCordFabric}
            cushionFabric={selectedFabricsAndFinishes.cushionFabric}
            insideBack={selectedFabricsAndFinishes.insideBack}
            throwPillowFabric={selectedFabricsAndFinishes.throwPillowFabric}
            selectedBaseFinish={selectedFabricsAndFinishes.selectedBaseFinish}
            nailSize={selectedFabricsAndFinishes.nailSize}
            nailFinish={selectedFabricsAndFinishes.nailFinish}
            collectionPath={"benchmade-benches"}
            checkBoxOptionsCustom={checkBoxOptionsCustom}
            setCheckBoxOptionsCustom={setCheckBoxOptionsCustom}
            accessoryLeft={accessoryLeft}
            accessoryRight={accessoryRight}
            powerOption={powerOption}
            productDimensions={productDimensions}
            initialProduct={initialProduct}
          />
        );

      default:
        return <></>;
    }
  };

  const loadName = (type) => {
    switch (type) {
      case "Haven":
        return <div className={"custom-name"}>Haven Reserve</div>;
      case "Urban":
        return <div className={"custom-name"}>Urban Living</div>;
      case "Senior":
        return <div className={"custom-name"}>Senior Living Solutions</div>;
      case "Monogram":
        return <div className={"custom-name"}>Monogram</div>;
      case "BenchmadeRoundOttomans":
        return <div className={"custom-name"}>BenchMade Round Ottomans</div>;
      case "BenchmadeSquareOttomans":
        return <div className={"custom-name"}>BenchMade Square Ottomans</div>;
      case "BenchmadeBenches":
        return <div className={"custom-name"}>BenchMade Benches</div>;
      case "LiveEdge":
        return <div className={"custom-name"}>Live Edge</div>;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    setInitLoad(true);
    return () => {
      setInitLoad(false);
    };
  }, []);

  const searchObj = qs.parse(window.location.search);

  return (
    <>
      <div className={"custom-pdp"}>
        <div className="container-fluid">
          <Breadcrumbs className={"toolbar-breadcrumbs"} sku={sku} />
        </div>
        <div
          className={`custom-left
          ${isFullscreen ? "fullscreen-canvas" : ""}`}
        >
          {!isMobileOrTablet ? (
            <Gallery gallery={product.media_gallery} hideLabel={true} />
          ) : null}
          {initLoad && loadPlayer(type)}
        </div>
        <div className={"custom-right"}>
          {isMobileOrTablet ? (
            <Gallery gallery={product.media_gallery} hideLabel={true} />
          ) : null}
          <Specs
            availableIn={availableIn}
            mappedSpecs={mappedSpecs}
            isCustom={true}
            sku={sku}
            isLiveEdge={isLiveEdge}
          />
          {pathnameIncludes("build-your-own/live-edge") ? (
            <div
              className={"view-more-title"}
              id={`frame-options`}
              onClick={(e) => {
                if (toggleFrameOptions) {
                  let selectionHolder = document.querySelector(".select-frame");
                  if (selectionHolder?.classList) {
                    selectionHolder.classList.remove("slide-in");
                    selectionHolder.classList.add("slide-out");
                  }
                  setTimeout(() => {
                    setToggleFrameOptions(false);
                  }, 500);
                } else {
                  setToggleFrameOptions(true);
                }
              }}
            >
              <h1 className="option-title">
                {!isLiveEdge ? "Top " : "Top & Base Selections"}
              </h1>
              <IconContainer opened={toggleFrameOptions} />
            </div>
          ) : null}
          {toggleFrameOptions &&
          (!searchObj?.edit ||
            (searchObj?.edit &&
              window.location.pathname.includes("build-your-own/monogram"))) ? (
            <div
              className={`select-frame ${
                toggleFrameOptions ? "slide-in" : "slide-out"
              }`}
            >
              <Steps
                type={type}
                initialProduct={initialProduct}
                toggleFrameOptions={toggleFrameOptions}
                sku={sku}
                frame={frame}
                back={back}
                arm={arm}
                base={base}
                seat={seat}
                seatAndBack={seatAndBack}
                accessoryLeft={accessoryLeft}
                accessoryRight={accessoryRight}
                powerOption={powerOption}
                model={model}
                size={size}
                top={top}
                nail={nail}
                striping={striping}
                finish={finish}
                setFrame={setFrame}
                setBack={setBack}
                setArm={setArm}
                setBase={setBase}
                setSeat={setSeat}
                setSeatAndBack={setSeatAndBack}
                setAccessoryLeft={setAccessoryLeft}
                setAccessoryRight={setAccessoryRight}
                setPowerOption={setPowerOption}
                setModel={setModel}
                setStriping={setStriping}
                setFinish={setFinish}
                modelReady={modelReady}
                setModelReady={setModelReady}
                contrastDrawers={contrastDrawers}
                setContrastDrawers={setContrastDrawers}
                setFabric={setFabric}
                setNail={setNail}
                setSize={setSize}
                setTop={setTop}
                setBaseFinish={setBaseFinish}
                searchObj={searchObj}
              />
            </div>
          ) : null}
          <Options
            options={product.options}
            optionState={optionState}
            optionDispatch={optionDispatch}
            productImage={product.image}
            isCustom={true}
            type={type}
            player={player}
            toggleFrameOptions={toggleFrameOptions}
            sku={product.sku}
            byoSku={sku}
            leatherAvailable={product.available_in_leather}
            isPelleProvisions={isPelleCollection()}
            ffi_fullyuph={product?.ffi_fullyuph}
            product={product}
            customName={loadName(type)}
            finishError={finishError}
            setFinishError={setFinishError}
            frame={frame}
            priceDispatch={priceDispatch}
          />
        </div>
      </div>
      <Toolbar
        sku={sku}
        numOfBases={numOfBases}
        liveEdgePrice={liveEdgePrice}
        name={product.name}
        calculateOptions={calculateOptions}
        id={product.id}
        isCustom={true}
        player={player}
        optionDispatch={optionDispatch}
        downloadTearSheet={tearSheet}
        image={product.image}
        product={product}
        isEdit={isEdit}
        optionState={optionState}
        availableIn={availableIn}
        description={product.ffi_extdesc2}
        customName={loadName(type)}
        top={top}
        base={base}
        finishError={finishError}
        setFinishError={setFinishError}
      />
      {tag}
    </>
  );
};

export default CustomPDP;
